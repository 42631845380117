import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs';
import { AppEpic } from './store';
import {
  setActivePageComponentPropsEpic,
  setActivePageFilterSensitivityEpic,
  setupSettings,
  setLocalization,
  checkIfMfpModuleIsReady,
  getMfpScopeMembers,
} from 'src/pages/NavigationShell/NavigationShell.epics';
import { summaryViewLoad } from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.epics';
import { canvasViewLoad } from './pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.epics';
import { collectionViewLoad } from './pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.epics';
import {
  fetchFloorsetsOnPageLoad,
  floorsetComparisonDataLoad,
} from './pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.epics';
import { nestedViewLoad } from './pages/Hindsighting/HistoryGrid/NestedView/NestedView.epics';
import { listViewLoad } from './pages/Hindsighting/HistoryGrid/ListView/ListView.epics';
import { worklistConfigLoaded, worklistCompanionLoad, worklistCompanionReset } from './pages/Worklist/Worklist.epics';
import { productDetailsLoad } from './pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.epic';
import { nestedAttributeLoad } from './pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.epic';
import { paretoAnalysisLoad } from './pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.epic';
import { categorySummaryLoad } from './pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.epic';
import { productMixLoad } from './pages/Hindsighting/CategoryRecap/ProductMix/ProductMix.epic';
import { productivityLoad } from './pages/Hindsighting/CategoryRecap/Productivity/Productivity.epic';
import { assortmentAddLoad, assortmentAddSearch } from './pages/AssortmentBuild/AssortmentAdd/AssortmentAddView.epic';
import { gridViewLoad } from './pages/Hindsighting/StyleColorReview/GridView/GridView.epics';
import { topPerformersLoad } from './pages/Hindsighting/MacroTrends/TopPerformers/TopPerformers.epics';
import { flowTypeLoad } from './pages/Hindsighting/StyleColorReview/FlowType/FlowType.epics';
import { topTyLyLoad } from './pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.epics';
import {
  enhancedOvertimeDataLoad,
  enhancedOvertimeConfigLoad,
} from 'src/components/EnhancedOvertime/EnhancedOvertime.epics';
import {
  quickTrendsMaybeFloorsetLoad,
  quickTrendsTrendDataLoad,
} from './pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends.epics';
import {
  configurableGridConfigLoad,
  configurableGridFloorsetLoad,
  configurableGridLoad,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.epics';
import { styleByLevelOvertimeLoad } from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime.epics';
import { cleanUpEntirePageSlice } from './pages/Pages.epics';
import {
  flowSheetByStyleCompanionLoad,
  flowSheetByStyleOvertimeLoad,
} from './pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.epic';
import {
  pricingOverTimeLoad,
  pricingOvertimeCompanionLoad,
} from './pages/AssortmentBuild/Pricing/PricingOverTime.epic';
import { planRefresh } from './components/Headerbar/Header.epics';
import { nestedOvertimeLoad } from './pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertime.epics';
import { undoItemUpdate } from './pages/AssortmentBuild/Planning.epics';
import { loadTargetList } from './pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.epics';
import showQuickReconcileEpic from './components/Reconcilation/QuickRecapView/QuickReconcile.epics';
import {
  setMetricConfigEpic,
  requestSummarizedDataEpic,
  setMetricConfigEpicForMacroSummRenderer,
  setMetricConfigEpicForMacroFavorites,
  requestSummarizedDataEpicForMacroFavorites,
} from 'src/components/Mfp/MacroSummaries/MacroSummaries.epics';
import {
  clearMfpScope,
  handleFetchCommands,
  handleFetchMfpAvailableMembers,
  refreshGrid,
} from 'src/state/scope/Scope.epics';
import {
  refreshNonWorkingSummary,
  refreshWorkflowsOnLatestPublish,
  setActiveSummaryPlan,
} from 'src/state/workingSets/nonWorkingSets.epics';
import { refreshChartPivot } from 'src/components/Mfp/EditableChart/EditableChart.epics';
import { changeEditableStateOfComments, scopeReadyGetComments } from 'src/components/Mfp/Comments/Comments.epics';
import { saveLastCreatedScope } from 'src/state/uipose/UiPose.epics';
import { trendSummaryRefresh } from 'src/components/Mfp/QuickTrends/TrendSummary/TrendSummary.epics';
import { trendDetailsRefresh } from 'src/components/Mfp/QuickTrends/TrendDetails/TrendDetails.epics';

import { toast } from 'react-toastify';
import ServiceContainer from './ServiceContainer';
import { setupAppConfigFlowStatus } from './services/configuration/AppConfig.epics';
import { styleEditViewLoad } from './pages/AssortmentBuild/StyleEdit/StyleEdit.epics';
import { geoTrendsLoad } from './pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.epics';
import { fetchFilterSelectionsOnLoad, handleFetchFilters } from './components/FilterPanel/FilterPanel.epics';
import {
  clearExtraPivotParamsOptionsEpic,
  setBreadcrumbs,
  setExtraPivotParamsConfigEpic,
} from './components/Subheader/Subheader.epics';
import { clearAssortmentCart, initAssortmentCart, resetCartEpic } from './pages/AssortmentCart/AssortmentCart.epics';
export const rootEpic: AppEpic = (action$, store$, dependencies) =>
  combineEpics(
    setActivePageComponentPropsEpic,
    setActivePageFilterSensitivityEpic,
    styleEditViewLoad,
    gridViewLoad,
    summaryViewLoad,
    canvasViewLoad,
    fetchFloorsetsOnPageLoad,
    floorsetComparisonDataLoad,
    listViewLoad,
    nestedViewLoad,
    worklistConfigLoaded,
    worklistCompanionLoad,
    worklistCompanionReset,
    productDetailsLoad,
    nestedAttributeLoad,
    paretoAnalysisLoad,
    categorySummaryLoad,
    productMixLoad,
    productivityLoad,
    assortmentAddLoad,
    assortmentAddSearch,
    collectionViewLoad,
    topPerformersLoad,
    flowTypeLoad,
    topTyLyLoad,
    enhancedOvertimeDataLoad,
    enhancedOvertimeConfigLoad,
    quickTrendsMaybeFloorsetLoad,
    configurableGridConfigLoad,
    configurableGridFloorsetLoad,
    configurableGridLoad,
    styleByLevelOvertimeLoad,
    nestedOvertimeLoad,
    cleanUpEntirePageSlice,
    flowSheetByStyleCompanionLoad,
    flowSheetByStyleOvertimeLoad,
    pricingOvertimeCompanionLoad,
    pricingOverTimeLoad,
    planRefresh,
    undoItemUpdate,
    loadTargetList,
    showQuickReconcileEpic,
    setupAppConfigFlowStatus,
    requestSummarizedDataEpic,
    setupSettings,
    checkIfMfpModuleIsReady,
    setLocalization,
    refreshGrid,
    setActiveSummaryPlan,
    refreshNonWorkingSummary,
    refreshWorkflowsOnLatestPublish,
    scopeReadyGetComments,
    changeEditableStateOfComments,
    saveLastCreatedScope,
    refreshChartPivot,
    trendSummaryRefresh,
    setMetricConfigEpic,
    setMetricConfigEpicForMacroSummRenderer,
    setMetricConfigEpicForMacroFavorites,
    requestSummarizedDataEpicForMacroFavorites,
    trendDetailsRefresh,
    handleFetchCommands,
    handleFetchMfpAvailableMembers,
    clearMfpScope,
    geoTrendsLoad,
    handleFetchFilters,
    fetchFilterSelectionsOnLoad,
    quickTrendsTrendDataLoad,
    getMfpScopeMembers,
    setBreadcrumbs,
    setExtraPivotParamsConfigEpic,
    clearExtraPivotParamsOptionsEpic,
    resetCartEpic,
    clearAssortmentCart,
    initAssortmentCart
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // final backstop error handler for epics
      toast.error(`An error occurred in the application`);
      ServiceContainer.loggingService.error(
        `An error fell through to the epics.ts catch() with the message ${error.message}`,
        error.stack
      );
      return source;
    })
  );
