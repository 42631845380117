import * as React from 'react';
import type { ListProps, ListRowRenderer, Size } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { List } from 'react-virtualized/dist/es/List';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import FlatCard, { FlatCardProps } from './FlatCard/FlatCard';
import {
  companionListViewStyle,
  flatCardStyle,
  cardHeight,
  dropDownStyle,
  menuListStyle,
  levelByDropDownStyle,
  dropdownSelectedValue,
} from './CompanionListView.styles';
import { KeyVal } from '../../common/object';
import { isNil, isEqual, isEmpty } from 'lodash';
import { CompanionDataLookup } from 'src/utils/Component/ListView';
import { BasicViewItem } from 'src/services/configuration/codecs/viewdefns/general';
import { Skeleton } from '@material-ui/lab';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

/**
 * @deprecated
 * This type should no longer be referenced.
 * Instead please refer to the types BasicViewItem for sort items and GroupViewItem for grouping.
 * TODO: Remove type where referenced in favor of BasicViewItem
 * */
export type SortOption = Pick<BasicViewItem, 'text' | 'dataIndex'>;

export interface ScrollTo {
  eventId: number;
  where: KeyVal<string, any>;
}

/**
 * Represents a companion list item
 *
 * @interface ListViewable
 * @property {string} id - Configured 'title' field.
 * @property {string} name - Configured 'body' of companion view.
 * @property {number|null} [stars] - Optional star rating, nullable if not rated.
 * @property {string} [imageUri] - Optional URI of the image associated with the item.
 * @property {string} [title] - Configured displayTitle, because id & name desynced and has to be specified separately.
 */
export interface ListViewable {
  id: string;
  name: string;
  stars: number | null;
  imageUri?: string;
  title?: string;
  [key: string]: any | undefined;
}

export interface Props {
  defaultSelection: number;
  sortOptions: SortOption[];
  data: ListViewable[];
  isDataloaded: boolean;
  label: string;
  className: string;
  scrollTo?: ScrollTo;
  noImageUrl?: string;
  selectedIndex?: number;
  searchComponent?: JSX.Element;
  filterComponent?: JSX.Element;
  initialSortDirection?: SortByDirection;
  levelOptions?: SortOption[];
  defaultLevelSelection?: number;
  hoverListItemElement?: JSX.Element;
  isCollapsed?: boolean;
  dataLookup?: CompanionDataLookup;
  onListItemClicked?(id: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onSortSelection?(sortOption: SortOption): void;
  onChangeDirection?(direction: SortByDirection): void;
  onToggleCollapse?(isCollapsed: boolean): void;
  onLevelSelection?(levelOption: SortOption): void;
}

export interface State {
  direction: SortByDirection;
  isCollapsed: boolean;
  selection: number;
  levelSelection: number;
}

const companionLoadingSkeleton: ListRowRenderer = (props) => {
  return (
    <div style={props.style} key={props.index}>
      <div className="companion-list-skeleton-container">
        <div className="companion-left-skeleton-container">
          <Skeleton animation="wave" variant="text" width="70%" />
          <Skeleton animation="wave" variant="rect" height="50%" />
          <Skeleton animation="wave" variant="text" width="70%" height="20%" />
        </div>
        <div className="companion-right-skeleton-container">
          <Skeleton animation="wave" variant="rect" className="companion-list-skeleton" height="100%" width="100%" />
        </div>
      </div>
    </div>
  );
};
export default class CompanionListView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      direction: isNil(props.initialSortDirection) ? 'desc' : props.initialSortDirection,
      isCollapsed: props.isCollapsed || false,
      selection: props.defaultSelection,
      levelSelection: props.defaultLevelSelection || 0,
    };
    this.selectDropdown = this.selectDropdown.bind(this);
    this.toggleDirection = this.toggleDirection.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.defaultSelection != prevProps.defaultSelection) {
      this.setState({
        selection: this.props.defaultSelection,
      });
    }
    if (this.props.initialSortDirection != prevProps.initialSortDirection && this.props.initialSortDirection) {
      this.setState({
        direction: this.props.initialSortDirection,
      });
    }
    if (this.props.isCollapsed != prevProps.isCollapsed && !isNil(this.props.isCollapsed)) {
      this.setState({
        isCollapsed: this.props.isCollapsed,
      });
    }
    if (!isEqual(this.props.levelOptions, prevProps.levelOptions)) {
      this.setState({
        levelSelection: 0,
      });
    }
  }

  selectDropdown(selection: number) {
    this.setState({
      selection,
    });

    if (this.props.onSortSelection && this.props.sortOptions != null && selection > -1) {
      this.props.onSortSelection(this.props.sortOptions[selection]);
    }
  }

  selectLevelDropdown(selection: number) {
    this.setState({
      levelSelection: selection,
    });

    if (this.props.onLevelSelection && this.props.levelOptions) {
      this.props.onLevelSelection(this.props.levelOptions[selection]);
    }
  }

  toggleCollapse() {
    const isCollapsed = !this.state.isCollapsed;
    this.setState({ isCollapsed });

    if (this.props.onToggleCollapse) {
      this.props.onToggleCollapse(isCollapsed);
    }
  }

  toggleDirection() {
    const direction = this.state.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ direction });

    if (this.props.onChangeDirection) {
      this.props.onChangeDirection(direction);
    }
  }

  render() {
    const {
      sortOptions,
      levelOptions,
      data,
      className,
      onListItemClicked,
      selectedIndex,
      scrollTo,
      label = 'Count',
      searchComponent,
      filterComponent,
      dataLookup,
    } = this.props;
    const { selection: orderBySelection, levelSelection } = this.state;

    const scrollToIndex = !isNil(scrollTo)
      ? data.findIndex((datum) => datum[scrollTo.where.key] === scrollTo.where.value)
      : undefined;
    const hideStars = dataLookup && dataLookup.starsId === null;
    const noImageUrl = dataLookup && dataLookup.imageUrlId ? this.props.noImageUrl : '';

    const rowRenderer: ListRowRenderer = (props) => {
      const datum = data[props.index];
      if (hideStars) datum.stars = null;

      const cardClasses =
        flatCardStyle +
        (selectedIndex === props.index ? ' scroll-target' : '') +
        (this.state.isCollapsed ? ' collapsed' : '');

      const flatCardProps: FlatCardProps = {
        ...datum,
        noImageUrl,
        index: props.index,
        className: cardClasses,
        hoverElement: this.props.hoverListItemElement,
        cardHeight,
        onClick: onListItemClicked,
      };

      return (
        <div style={props.style} key={props.index}>
          <FlatCard {...flatCardProps} />
        </div>
      );
    };

    const noRowsRenderer = () => {
      return <div className="no-item">No Items To Show</div>;
    };
    const contentRender = !this.props.isDataloaded ? companionLoadingSkeleton : rowRenderer;
    const listOptions: ListProps = {
      overscanRowCount: 10,
      noRowsRenderer: noRowsRenderer,
      rowHeight: cardHeight,
      rowRenderer: contentRender,
      rowCount: !this.props.isDataloaded ? 4 : isEmpty(data) ? 0 : data.length,
      tabIndex: -1,
      scrollToAlignment: 'start',
      scrollToIndex,
      // Must be overwritten by autosizer, this is just type error avoidance
      height: NaN,
      width: NaN,
    };

    const classes1 =
      companionListViewStyle +
      ' companion-list-view' +
      (className ? ` ${className}` : '') +
      (this.state.isCollapsed ? ' collapsed' : '');

    const directionClasses = 'sort-dir far ' + (this.state.direction === 'asc' ? ' fa-arrow-down' : ' fa-arrow-up');

    function renderList(size: Size) {
      const options = {
        ...listOptions,
        ...size,
      };
      return <List {...options} />;
    }

    const { isCollapsed } = this.state;
    let controls;
    if (!isEmpty(sortOptions) && orderBySelection >= 0) {
      controls = (
        <React.Fragment>
          <FormControl classes={{ root: dropDownStyle(!this.state.isCollapsed) }}>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: { paper: menuListStyle },
                getContentAnchorEl: null,
              }}
              disableUnderline={true}
              value={sortOptions[orderBySelection].text}
              renderValue={(value) => (
                <div
                  style={
                    this.state.isCollapsed ? { ...dropdownSelectedValue } : { ...dropdownSelectedValue, width: '100%' }
                  }
                >
                  {value}
                </div>
              )}
            >
              {sortOptions.map((option, index) => (
                <MenuItem key={index} value={index} onClick={() => this.selectDropdown(index)}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span
            className={directionClasses}
            style={{ position: 'relative', right: !this.state.isCollapsed ? -5 : -3 }}
            onClick={this.toggleDirection}
          />
        </React.Fragment>
      );
    }

    const collapsedControls = isCollapsed && controls ? controls : <span className="spacer" />;
    const expandControls = isCollapsed && controls ? null : controls;
    let expandLevelControls;
    if (!isCollapsed && levelOptions) {
      expandLevelControls = (
        <div className="control-bar level-bar">
          <label>Level:</label>
          <FormControl classes={{ root: levelByDropDownStyle }}>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: { paper: menuListStyle },
                getContentAnchorEl: null,
              }}
              disableUnderline={true}
              value={levelSelection}
            >
              {levelOptions.map((option, index) => (
                <MenuItem key={index} value={index} onClick={() => this.selectLevelDropdown(index)}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span style={{ width: 17 }} />
        </div>
      );
    }

    return (
      <div className={classes1}>
        <header style={{ padding: this.state.isCollapsed ? '1rem 0.5rem' : '1rem' }}>
          <div className="expander-container">
            <span className="label">
              {label}: {data.length}
            </span>
            <span className="expander" onClick={this.toggleCollapse}>
              &#60;
            </span>
          </div>
          <div style={{ display: 'flex' }}>{collapsedControls}</div>

          <div className="extra-controls">
            {!isNil(searchComponent) && <div className="search">{searchComponent}</div>}
            {!isNil(filterComponent) && <div className="filter">{filterComponent}</div>}
          </div>
          <div className="control-bar">
            <label>Order By:</label>
            {expandControls}
          </div>
          {expandLevelControls}
        </header>
        <div className={isEmpty(this.props.data) && this.props.isDataloaded ? 'no-items-container' : 'list-container'}>
          <AutoSizer>{renderList}</AutoSizer>
        </div>
      </div>
    );
  }
}
