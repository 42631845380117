import React, { Component, Fragment } from 'react';
import { keys, isNil, isArray, mapKeys, invert, some, isEmpty, defaultTo, sumBy } from 'lodash';
import { toast } from 'react-toastify';

import { CardActions, MuiThemeProvider } from '@material-ui/core';
import Axios from 'src/services/axios';
import ServiceContainer from 'src/ServiceContainer';
import { Overlay } from 'src/common-ui';
import {
  ConfigurableDataModalProps,
  ConfigurableDataModalState,
  RenderSectionType,
  ConfigurableDataModalCellEditParams,
  ConfigurableDataConfig,
  FormattedSizingData,
  ServerSizingData,
  ViewSizingData,
  ConfigurableDataModalDataHandler,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.types';
import { addEventListenerForEditors } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/Editors.utils';
import { TemplateModalTitle } from 'src/components/TemplateModalWithButton/TemplateModalWithButton';
import {
  formatDataForView,
  renderItemsInSection,
  calculateInitialPercentages,
  modifyDataPercentages,
  disaggregateDataByPercentages,
  formatDataForServer,
  handleGetValue,
  fetchEditables,
  formatDataGranularPayload,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.utils';
import { ConfigurableDataModalTabs } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModalSections';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';
import styles, {
  modalContainer,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.styles';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { muiTheme } from 'src/utils/Style/Theme';

export default class ConfigurableDataModal extends Component<ConfigurableDataModalProps, ConfigurableDataModalState> {
  constructor(props: ConfigurableDataModalProps) {
    super(props);

    addEventListenerForEditors(props.eGridCell);

    this.state = {
      isLoading: true,
      editsSaved: false,
      config: null,
      data: {},
      activeTabIndex: 0,
      width: null,
      height: null,
    };
  }

  componentDidMount() {
    this.fetchData().catch((err) => {
      toast.error('An error occurred fetching data for the configuration for the modal');
      ServiceContainer.loggingService.error(`An error occurred: ${err.message}`, err.stack);
      this.props.stopEditing();
    });
  }

  async fetchData() {
    const { configApi, isEditable } = this.props;
    const configResponse = await Axios.get(configApi.url).catch((e) => {
      toast.error('An error occurred fetching the configuration for the modal');
      ServiceContainer.loggingService.error(`An error occurred: ${e.message}`, e.stack);
    });
    if (configResponse) {
      const config = maybeReturnNestData(configResponse.data) as ConfigurableDataConfig;
      const fetchHandler = config.dataHandlers.fetch;
      let formattedData: FormattedSizingData = {};

      switch (fetchHandler.type) {
        case ConfigurableDataModalDataHandler.receipts: {
          const params = this.getRequestParams(fetchHandler.defnId, fetchHandler.apiIdDataIndex);
          const dataResponse = await Axios.get(fetchHandler.url, { params }).catch((_e) => {
            toast.error(`Failed to get data for editing ${config.title}`);
            ServiceContainer.loggingService.error(
              `Failed to fetch data for ${fetchHandler.defnId} and ${fetchHandler.apiIdDataIndex}`
            );
          });
          if (dataResponse) {
            const deserializedData: ServerSizingData[] = ServiceContainer.pivotService.deserialize(
              dataResponse.data
            ) as ServerSizingData[];
            formattedData = formatDataForView(config, deserializedData);
          }
          break;
        }
        case ConfigurableDataModalDataHandler.pivot: {
          const params = processApiParams(fetchHandler, this.props.node.data).params as ListDataOptions;
          if (isArray(params.topMembers)) {
            params.topMembers = params.topMembers.join(',');
          }
          const dataResponse = await ServiceContainer.pivotService
            .listData(fetchHandler.defnId, ASSORTMENT, params)
            .catch((error): Pivot => {
              toast.error(`Failed to get data for editing ${config.title}`);
              ServiceContainer.loggingService.error(
                `Failed to fetch pivot for ConfigurableDataModal ${fetchHandler.defnId}`,
                error
              );
              this.props.stopEditing();
              return { flat: [], tree: [] };
            });
          if (dataResponse.tree.length == 0) {
            console.warn('No Data was returned for ConfigurableDataModal'); // eslint-disable-line no-console
          }
          formattedData = formatDataForView(config, dataResponse.tree as unknown as ServerSizingData[]);
          break;
        }
        default:
          toast.error(
            'The modal is misconfigured and will be closed automatically.  Check the fetchHandler type for correctness'
          );
          ServiceContainer.loggingService.error(`Failed to fetch pivot for ConfigurableDataModal`);
          this.props.stopEditing();
      }

      formattedData = !isEditable ? formattedData : calculateInitialPercentages(config, formattedData);
      this.setState({
        isLoading: false,
        config,
        data: formattedData,
      });
    }
  }

  getRequestParams = (defnId: string, idDataIndex: string) => {
    const { node, floorset } = this.props;
    return {
      appName: ASSORTMENT,
      productId: node.data[idDataIndex],
      timeId: floorset,
      defnId,
    };
  };

  getValue = () => {
    const { editsSaved, config, data } = this.state;
    const { value, floorset, node, cellDataIndex } = this.props;
    const gridData = {
      floorset,
      node,
      value,
      cellDataIndex,
    };

    if (isNil(config)) {
      return value;
    }

    return handleGetValue(config, editsSaved, data, gridData);
  };

  isPopup = () => true;

  handleTabChange = (activeTabIndex: number) => {
    this.setState({
      activeTabIndex,
    });
  };

  handleCellEdit = (rowDataIndexValue: string, colDataIndex: string, value: number) => {
    const { activeTabIndex, data, config } = this.state;

    if (isNil(config)) {
      return;
    }

    // can get NaN values returned if cell was "cleared" out
    const initialValue = !isNaN(value) ? value : null;
    let modifiedValue = initialValue;

    // get/calculate previous value for current tab+cell from current data in state

    const tabs = keys(data);
    const activeTabKey = tabs[activeTabIndex];
    const tabData: ViewSizingData[] = data[activeTabKey].items ?? [];
    let prevValue: number | null;

    if (rowDataIndexValue === 'Total') {
      // calc sum of row values for total row
      const sumOfValues = sumBy(tabData, colDataIndex);
      prevValue = sumOfValues;
    } else {
      const modifiedItem = tabData.find((dataItem) => dataItem[config.rowSortByDataIndex] === rowDataIndexValue);
      prevValue = modifiedItem ? (modifiedItem[colDataIndex] as number) : null;
    }

    if (prevValue === initialValue) {
      return; // values match, skip edit
    }

    // check if value is null for modified cell but other row values are present
    const cellValueCleared = tabData.every((dataItem) => {
      if (dataItem[config.rowSortByDataIndex] === rowDataIndexValue) {
        // can ignore current cell's value, want to check others
        return true;
      }
      return !isNil(dataItem[colDataIndex]);
    });

    // on edit:
    // - a null value should become 0 if other values are present in the column (clearing a non-total row)
    // - a null value should remain null if all other values are null in the column (clearing the total row)

    if (isNil(initialValue) && cellValueCleared && rowDataIndexValue !== 'Total') {
      modifiedValue = 0;
    }

    const params: ConfigurableDataModalCellEditParams = {
      rowDataIndexValue,
      colDataIndex,
      value: modifiedValue,
      activeTabIndex,
      data,
      config,
    };

    const modifiedData =
      rowDataIndexValue !== 'Total' ? modifyDataPercentages(params) : disaggregateDataByPercentages(params);

    this.setState({
      data: modifiedData,
    });
  };

  handleCancel = () => {
    this.props.stopEditing(true);
  };

  handleSave = async () => {
    const { config } = this.state;
    if (isNil(config)) {
      return;
    }

    const { stopEditing } = this.props;
    const updateHandler = config.dataHandlers.update;

    switch (updateHandler.type) {
      case ConfigurableDataModalDataHandler.receipts: {
        const serverFormattedData = formatDataForServer(this.state.data, config.columns);
        const params = this.getRequestParams(updateHandler.defnId, updateHandler.apiIdDataIndex);
        await Axios.post(updateHandler.url, serverFormattedData, { params })
          .catch((error) => {
            toast.error('An error occured submitting your save');
            ServiceContainer.loggingService.error(
              `Failed to update data for ConfigurableDataModal ${updateHandler.url}`,
              error
            );
          })
          .finally(() => {
            this.setState({ editsSaved: true }, () => stopEditing());
          });
        break;
      }
      case ConfigurableDataModalDataHandler.granular: {
        const data = formatDataGranularPayload(this.state.data, config.columns, updateHandler.coordinateMap);

        await ServiceContainer.pivotService
          .granularEditSubmitData(data)
          .catch((e) => {
            toast.error('An error occurred updating data');
            ServiceContainer.loggingService.error(`An error occurred updating data, ${e.message}`, e.stack);
          })
          .finally(() => {
            this.setState({ editsSaved: true }, () => stopEditing());
          });
        break;
      }
      case ConfigurableDataModalDataHandler.attribute: {
        const data = fetchEditables(this.state.data, config.columns, Object.values(updateHandler.keys));
        const invertedKeys = invert(updateHandler.keys);
        const processedData = data.map((d) => {
          return mapKeys(d, (_v, k) => {
            if (!isNil(invertedKeys[k])) {
              return invertedKeys[k]; //assume the configurer is not daft here
            } else {
              return k.replace('attribute:', '').replace('member:', '').replace(':id', '').replace(':name', '');
            }
          });
        });
        await Axios.post('api/attribute/upsertAll?appName=Assortment', processedData)
          .catch((e) => {
            toast.error('An error occurred attempting to update your plan');
            ServiceContainer.loggingService.error(
              `An error occurred attempting to update your plan, ${e.message}`,
              e.stack
            );
          })
          .finally(() => {
            this.setState({ editsSaved: true }, () => stopEditing());
          });
        break;
      }
      default:
        toast.error('Failed to update data.');
        ServiceContainer.loggingService.error(`An error occurred attempting to update data`);
    }
  };

  // for some reason certain key strokes still propagate (i.e. tab) so this properly handles that
  bindEventListeners = (element: HTMLDivElement) => {
    if (isNil(element)) {
      return;
    }
    this.setState({
      width: element.offsetWidth,
      height: element.offsetHeight,
    });

    element.addEventListener('keydown', function (event: KeyboardEvent) {
      event.stopPropagation();
    });
  };

  renderSection(sectionType: RenderSectionType) {
    const { config, data, activeTabIndex } = this.state;
    const { isEditable, renderTabs } = this.props;

    let tabData: ViewSizingData[] = [];
    const tabs = keys(data);

    if (renderTabs) {
      const activeTabKey = tabs[activeTabIndex];
      tabData = data[activeTabKey].items;
    } else {
      // for now if not rendering tabs, selecting first week item
      const firstTab = tabs.length > 0 ? tabs[0] : '';
      tabData = firstTab ? data[firstTab].items : [];
    }

    return renderItemsInSection(sectionType, isEditable, config, tabData, this.handleCellEdit);
  }

  render() {
    const { renderTabs, isEditable } = this.props;
    const { isLoading, config, data, activeTabIndex } = this.state;
    const tabs = keys(data);
    const cols = config != null ? config.columns : [];
    const showAccept = isEditable && some(cols, (c) => c.editable === true);
    const showTotalRow = defaultTo(this.state.config?.showTotalRow, true);

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={styles.overlay}>
          <div data-qa="ag-popover" className={modalContainer(this.state.width, this.state.height)}>
            <div className={styles.header}>
              <TemplateModalTitle title={!isNil(config) ? config.title : ''} onCancel={this.handleCancel} />
            </div>
            {isLoading ? (
              <Overlay type="loading" visible={true} fitParent={true} />
            ) : (
              <Fragment>
                {renderTabs && (
                  <ConfigurableDataModalTabs
                    tabs={tabs}
                    activeTabIndex={activeTabIndex}
                    onTabChange={this.handleTabChange}
                  />
                )}
                {isEmpty(data) ? (
                  <div className={styles.noDataSection}>No data found</div>
                ) : (
                  <div className={styles.sectionsContainer} ref={this.bindEventListeners}>
                    {showTotalRow ? this.renderSection(RenderSectionType.top) : null}
                    {this.renderSection(RenderSectionType.bottom)}
                  </div>
                )}
                <CardActions classes={styles.actionButtons}>
                  {showAccept && <AcceptButton onClick={this.handleSave} />}
                  <RejectButton onClick={this.handleCancel} />
                </CardActions>
              </Fragment>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
