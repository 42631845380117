import { style } from 'typestyle/lib';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { STYLED_ACCORDION_SUMMARY_HEIGHT } from 'src/components/StyledAccordion/StyledAccordion.styles';

export const MACRO_SUMMARIES_HEIGHT = 149;
const macroSummaryContainerHeight = 250;

export const dataContainerStyle = style({
  display: 'flex',
  flexDirection: 'column',
  height: '99%',
});

export const macroGridStyle = style({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.header-row': {
      fontWeight: 700,
    },
    '.macro-container': {
      height: `calc(100% - ${SUBHEADER_HEIGHT}px - 0.2rem)`,
      $nest: {
        '& .macro-summaries': {
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        },
      },
    },
    '.grid-container': {
      height: `calc(100% - ${macroSummaryContainerHeight}px)`,
      $nest: {
        '&.expanded': {
          height: `calc(100% - ${STYLED_ACCORDION_SUMMARY_HEIGHT}px - ${MACRO_SUMMARIES_HEIGHT}px)`,
        },
        '&.collapsed': {
          height: `calc(100% - ${STYLED_ACCORDION_SUMMARY_HEIGHT}px)`,
        },
        '& .data-grid': {
          height: '100%',
        },
      },
    },
  },
});

export const containerPrintMode = style({
  display: 'flex',
  flexDirection: 'column',
  $debugName: 'container-printmode',
  $nest: {
    '.data-container': {
      display: 'flex',
      flexDirection: 'column',
      height: 'initial',
      flex: '1 0',
      $nest: {
        '.macro-summaries': {
          height: 'initial',
        },
        '.grid-container': {
          display: 'flex',
          flex: '1 0 ',
          height: 'initial',
          $nest: {
            '.data-grid': {
              flex: '1 0',
              height: 'initial',
            },
          },
        },
      },
    },
  },
});
