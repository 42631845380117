import { z } from 'zod';
import {
  zConfigApi,
  zClientDataApi,
  zDataApi,
  zListDataApi,
  zRouteToLocationDataApi,
  o_HasTopMembers,
  o_HasWorklistFunctionality,
  o_HasPopover,
  o_HasTitle,
  o_HasHideableCompanion,
  o_HasUndoButton,
  o_HasFlowStatus,
  o_HasSubheaderDownloadLink,
  o_HasLookBackPeriod,
  o_IdentifierProps,
  o_HasSubheaderErrorText,
  o_HasFab,
  o_OptionalIdentifierProps,
  o_GroupingInfo,
  o_HasConfigure,
  o_HasFullHeight,
  o_HasSummaries,
  o_HasRangeSelector,
} from './confdefnView';
import { CartItemType } from './literals';
import { AppType } from './bindings.types';

export const zExtraPivotParam = z.object({
  text: z.string(),
  dataApi: zDataApi,
});

export type ExtraPivotParam = z.infer<typeof zExtraPivotParam>;

export const zSubheaderDefnProps = z.object({
  groupBy: z.string().optional(),
  sortBy: z.string().optional(),
  rollup: z.string().optional(),
  pareDown: z.string().optional(),
  countLimit: z.string().optional(),
  breadcrumbs: z.array(z.string()).optional(),
  extraPivotParams: z.record(zExtraPivotParam).optional(),
});

export interface SubheaderDefnProps extends z.infer<typeof zSubheaderDefnProps> {}

const DefnProps = z.object({
  model: z.string(),
  dataApi: zDataApi.optional(),
  assortmentModel: z.string().optional(),
  subheader: zSubheaderDefnProps,
  view: z.array(z.string()),
});

export interface DefnProps extends z.infer<typeof DefnProps> {}

/** Some finagling to use in ConfigGrid for ExtraPivotParams without exceeding the type limit */
const DefnPropsSubheaderOnly = DefnProps.pick({
  subheader: true,
});

const MultiDefnProps = z.object({
  models: z.array(z.string()),
  assortmentModel: z.string().optional(),
  subheader: zSubheaderDefnProps,
  view: z.array(z.string()),
});

export interface MultiDefnProps extends z.infer<typeof MultiDefnProps> {}

export const o_BaseDefnsComponentProps = (title: string) => {
  return { defns: DefnProps, ...o_HasTitle(title) };
};
export const o_BaseMultiDefnsComponentProps = (title: string) => {
  return { defns: MultiDefnProps, ...o_HasTitle(title) };
};
export const BaseDefnsComponentProps = (title: string) => {
  return z.object(o_BaseDefnsComponentProps(title));
};
export const BaseMultiDefnsComponentProps = (title: string) => {
  return z.object(o_BaseMultiDefnsComponentProps(title));
};

export const CollectionViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Collection View'),
  ...o_IdentifierProps,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasSubheaderDownloadLink,
  ...o_HasTopMembers,
});

export const CanvasViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Canvas View'),
  ...o_OptionalIdentifierProps,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasWorklistFunctionality,
  ...o_HasFlowStatus,
  ...o_HasSubheaderDownloadLink,
});

export const SummaryViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Summary View'),
  ...o_OptionalIdentifierProps,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasWorklistFunctionality,
  ...o_HasFlowStatus,
  ...o_HasSubheaderDownloadLink,
  ...o_HasTopMembers,
});

export const GridViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Grid View'),
  ...o_OptionalIdentifierProps,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasWorklistFunctionality,
  ...o_HasFlowStatus,
  ...o_HasSubheaderDownloadLink,
  ...o_HasTopMembers,
  ...o_HasHideableCompanion,
  ...o_HasUndoButton,
});

export const ColumnGroupedViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Floorset Comparison'),
  ...o_IdentifierProps,
  ...o_GroupingInfo,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasTopMembers,
  ...o_HasWorklistFunctionality,
  ...o_HasSubheaderDownloadLink,
  floorsetApi: zClientDataApi.optional(),
});

export const QuickTrendsComponentProps = z.object({
  ...o_BaseMultiDefnsComponentProps('Quick Trends'),
  ...o_HasTopMembers,
  ...o_HasLookBackPeriod,
  ...o_HasFlowStatus,
  graphDataApi: zListDataApi,
  gridDataApi: zListDataApi.optional(),
  floorsetApi: zClientDataApi.optional(),
});

// TODO: config should be setup to be models since there are two.
// TODO: we may want to set this up like ListDataConfig (DataApi) but for FitView since that is what the params.aggBy field is really for.
// GroupingInfo is needed so pareto aggBy isnt hardcoded
export const zParetoAnalysisComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Pareto Analysis'),
  ...o_GroupingInfo,
});
export const zParetoDetailsComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Pareto Details'),
  ...o_GroupingInfo,
  graphDataApi: zDataApi,
});

export const NestedAttributeComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Nested Attribute'),
  ...o_HasFlowStatus,
  ...o_HasSubheaderErrorText,
  ...o_HasConfigure,
  ...o_HasFullHeight,
  ...o_HasSummaries,
  ...o_HasTopMembers,
  noTreeColumnDefinition: z
    .boolean()
    .optional()
    .describe('This property is no longer used and can be removed'),
});

export const zEditableOverTimeGridComponentProps = z.object({
  ...o_BaseMultiDefnsComponentProps('Flow Sheet'),
  ...o_IdentifierProps,
  ...o_HasPopover,
  ...o_HasFab,
  ...o_HasFlowStatus,
  ...o_HasTopMembers,
  ...o_HasUndoButton,
  dataApi: zListDataApi.optional(),
});

export interface EditableOverTimeGridComponentProps extends z.infer<typeof zEditableOverTimeGridComponentProps> {}

const o_AssortmentAddDetailProps = {
  level: z.string(),
  cartItemType: CartItemType,
  showSelectAll: z
    .boolean()
    .optional()
    .default(false),
};
const o_AssortmentAddDetailPropsOptional = {
  level: z.string().optional(),
  cartItemType: CartItemType.optional(),
  showSelectAll: z
    .boolean()
    .optional()
    .default(false),
};

export const ConfigurableGridApiProps = {
  dataApi: zClientDataApi.or(zListDataApi),
  topAttributesApi: zClientDataApi.or(zListDataApi).optional(),
  configApi: zClientDataApi,
  planningApi: zClientDataApi,
  floorsetApi: zClientDataApi.optional(),
  isStyleColorEdit: z.boolean().optional(),
};
export const zConfigurableGridApiProps = z.object(ConfigurableGridApiProps);

export const ConfigurableGridComponentProps = z.object({
  ...ConfigurableGridApiProps,
  defns: DefnPropsSubheaderOnly.optional(),
  fabType: z.string().optional(),
  ...o_HasTopMembers,
  ...o_HasWorklistFunctionality,
  ...o_HasPopover,
  ...o_HasTitle('Assortment By Floorset'),
  ...o_HasHideableCompanion,
  ...o_HasUndoButton,
  ...o_HasFlowStatus,
  ...o_HasSubheaderDownloadLink,
  ...o_HasLookBackPeriod,
  ...o_IdentifierProps,
  ...o_HasSubheaderErrorText,
  topMemberObj: z.record(z.any()).optional(),
  showPublishText: z.boolean().optional(),
  publishText: z.string().optional(),
  publishAttribute: z.string().optional(),
  ...o_AssortmentAddDetailPropsOptional,
});

export const AssortmentAddViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Select Items'),
  ...o_AssortmentAddDetailProps,
  ...o_HasFab,
  ...o_HasFlowStatus,
  ...o_HasRangeSelector,
});

export const AssortmentAddBySearchComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Add Choices By Search'),
  ...o_AssortmentAddDetailProps,
  ...o_HasFab,
});

// FIXME: the above ConfigApi structure has different a shape and need to match the one in confdefnView.ts
const OvertimeConfigApi = z.object({
  params: z.object({
    appName: z.union([z.literal(AppType.Assortment), z.literal(AppType.TDAnalysis)]).optional(),
    defnId: z.string(),
    aggBy: z.string(),
    ignoreAncestors: z
      .boolean()
      .optional()
  }),
});

export const NestedOvertimeComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Nested Over Time'),
  dataApi: OvertimeConfigApi,
  companionDataApi: OvertimeConfigApi.optional(),
});

export const AssortmentPublishComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Assortment Publish'),
  ...o_IdentifierProps,
  ...o_HasSubheaderErrorText,
  dataApi: zListDataApi.optional(),
});

export const TargetListComponentProps = z.object({
  type: z.union([z.literal('Product'), z.literal('Location')]),
  view: z.array(z.string()),
});

export const SizeEligibilityListGridComponentProps = z.object({
  ...o_BaseDefnsComponentProps(''),
  ...o_HasSubheaderDownloadLink,
  viewProperty: z.string(),
  floorsetApi: zConfigApi.optional(),
  dataApi: zListDataApi,
  companionApi: zListDataApi.optional(),
});

export const WorklistComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Workflow'),
  ...o_HasFab,
  ...o_HasFlowStatus,
  ...o_HasHideableCompanion,
  showLevel: z.boolean().optional(),
  /**
   * Provided to allow bindings to access the path of the default worklist tab
   * without retrieving the worklist viewdefn when building routes.
   *
   * This needs to match a configured worklist tab's `pathSlot` value in the `defns.view[0]`
   * */
  defaultPathSlot: z.string().optional(),
});

export const HistoryGridComponentProps = z.object({
  ...o_BaseDefnsComponentProps('List View'),
  ...o_OptionalIdentifierProps,
  ...o_HasTopMembers,
  ...o_HasHideableCompanion,
  ...o_HasFab,
  ...o_HasFlowStatus,
});

export const TopPerformersComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Top Performers'),
  ...o_HasFlowStatus,
  ...o_HasWorklistFunctionality,
});

export const ExceptionsSummaryComponentProps = z.object({
  dataApi: zListDataApi,
  configApi: zConfigApi,
});

export const StyleEditComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Style Details View'),
  ...o_HasFab,
});
export const EnhancedOvertimeComponentProps = z.object({
  ...o_BaseMultiDefnsComponentProps(''),
  ...o_HasTopMembers,
});

export const AssortmentCartViewComponentProps = z.object({
  ...o_BaseDefnsComponentProps(''),
});
export const ProductivityComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Productivity'),
});
export const CategorySummaryComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Quick Snapshot'),
  ...o_HasTopMembers,
});
export const ProductMixComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Product Mix'),
});
export const ParameterTogglesComponentProps = z.object({
  ...o_BaseDefnsComponentProps(''),
  ...o_HasFab,
});
export const ProductDetailsComponentProps = z.object({
  ...o_BaseMultiDefnsComponentProps('Product Details'),
});
export const RouteToLocationComponentProps = z.object({
  dataApi: zRouteToLocationDataApi,
});
export const zMacroMixComponentProps = z.object({
  ...o_BaseDefnsComponentProps('Macro Mix'),
  ...o_HasFlowStatus,
  ...o_HasLookBackPeriod,
  dataApi: zListDataApi,
  chartDataApi: zListDataApi,
});
const o_SummaryDataApiList = {
  keyFinancialsDataApi: zListDataApi,
  choiceProductivityDataApi: zListDataApi,
  analysisDataApi: zListDataApi,
  productMixAndTrendDataApi: zListDataApi,
  geoTrendDataApi: zListDataApi,
};
export const zSummaryTopdownComponentProps = z.object({
  defns: z.object({
    model: z.string(),
    subheader: zSubheaderDefnProps,
    view: z.array(z.string()),
  }),
  ...o_HasTitle('Summary'),
  ...o_HasFlowStatus,
  ...o_HasLookBackPeriod,
  ...o_SummaryDataApiList,
});

export const zGeoTrendsComponentProps = z.object({
  dataApi: zListDataApi,
  graphDataApi: zListDataApi,
  ...o_HasLookBackPeriod,
  ...o_HasFlowStatus,
  ...o_HasTitle('Geo Trends'),
  ...o_HasFlowStatus,
  ...o_HasTopMembers,
  defns: z.object({
    view: z.array(z.string()),
  }),
});

const zDimensionItem = z.object({
  dimension: z.string(),
  items: z.array(z.string()),
  hierarchy: z.optional(z.string()),
});
const o_ViewParams = {
  viewParams: z.object({
    rows: z.array(zDimensionItem),
    columns: z.array(zDimensionItem),
    title: z.string(),
    summaryMetrics: z.object({
      metrics: zDimensionItem,
      revisions: zDimensionItem,
    }),
  }),
};
const o_SplitViewParams = {
  viewParams: z.object({
    title: z.string(),
    summaryMetrics: z.object({
      metrics: zDimensionItem,
      revisions: zDimensionItem,
    }),
    topGrid: z.object({
      rows: z.array(zDimensionItem),
      columns: z.array(zDimensionItem),
    }),
    bottomGrid: z.object({
      rows: z.array(zDimensionItem),
      columns: z.array(zDimensionItem),
    }),
  }),
};
const o_SubheaderBtns = {
  subheader: z.object({ extraBtns: z.array(z.string()).optional() }).optional(),
};
const zSubheaderBtns = z.object(o_SubheaderBtns);
export interface SubheaderBtnsComponentProps extends z.infer<typeof zSubheaderBtns> {}

export const zMfpSummaryGridComponentProps = z.object({
  ...o_HasTitle('Plan'),
  ...o_ViewParams,
  ...o_SubheaderBtns,
});
export const zMfpSplitViewComponentProps = z.object({
  ...o_HasTitle('Split View'),
  ...o_SplitViewParams,
  ...o_SubheaderBtns,
});
export const zMfpReviewPlansComponentProps = z.object({
  ...o_HasTitle('Review Plans'),
  ...o_ViewParams,
});
export interface MfpSummaryGridComponentProps extends z.infer<typeof zMfpSummaryGridComponentProps> {}

const SMARTPLAN_BALANCE = z.literal('Balance');
const SMARTPLAN_SUMMARY = z.literal('Summary');
const SMARTPLAN_TARGETS = z.literal('Targets');
const SMARTPLAN_GRID = z.literal('Grid');
const SMARTPLAN_DESCRIPTION = z.literal('Description');
const SMARTPLAN_TOGGLE = z.literal('toggle');
const SMARTPLAN_PERCENT = z.literal('inputPercent');
const SMARTPLAN_SLIDER = z.literal('slider');

const SMARTPLAN_INPUTS = z.union([SMARTPLAN_TOGGLE, SMARTPLAN_PERCENT, SMARTPLAN_SLIDER]);

export const zPivotOptions = z.object({
  rows: z.array(zDimensionItem),
  columns: z.array(zDimensionItem),
  name: z.string().optional(),
  groupBy: z
    .object({
      dimension: z.string(),
      item: z.string(),
    })
    .optional(),
  readOnly: z.boolean(),
});

export const zSmartPlanTarget = z.object({
  /* Basic text label for the input */
  text: z.string(),
  /* Popover text input for the string, HTML not allowed */
  infoText: z.string(),
  /* MetricId tied to the input */
  dataIndex: z.string(),
  /* What editor will be used for the input */
  editor: SMARTPLAN_INPUTS,
});

const SMARTPLAN_COMPONENTS = z.union([
  SMARTPLAN_BALANCE,
  SMARTPLAN_SUMMARY,
  SMARTPLAN_TARGETS,
  SMARTPLAN_GRID,
  SMARTPLAN_DESCRIPTION,
]);

export const zSmartPlanSection = z.object({
  title: z.string(),
  icon: z.string(),
  targets: z.array(zSmartPlanTarget),
});

export const zMfpSmartPlanComponentProps = z.object({
  /* Section Title (not implemented yet) */
  title: z.string(),
  /* Section Description */
  description: z.union([z.string(), z.null()]),
  /* Component Type */
  component: SMARTPLAN_COMPONENTS,
  /* Sections, leaves as empty array for non-target component */
  sections: z.union([z.null(), z.array(zSmartPlanSection)]),
  viewParams: z.union([zPivotOptions, z.null()]),
  ...o_SubheaderBtns,
});
export const zSmartPlanPlayload = z.object({
  steps: z.array(zMfpSmartPlanComponentProps),
});
export type SmartPlanPayload = z.infer<typeof zSmartPlanPlayload>;
export type SmartPlanStep = z.infer<typeof zMfpSmartPlanComponentProps>;
export type SmartPlanSection = z.infer<typeof zSmartPlanSection>;

export const zBulkImportComponentProps = z.object({
  ...o_HasTitle('Bulk Import'),
  importId: z.string(),
  stepper1Label: z
    .string()
    .optional()
    .default('Download a fresh template:'),
  stepper2Label: z
    .string()
    .optional()
    .default('Upload a completed template'),
  templateType: z
    .string()
    .optional()
    .default('import'),
});
