import React, { CSSProperties, forwardRef } from 'react';
import { Badge, Icon, Link, Tooltip, withStyles, createStyles, TooltipProps } from '@material-ui/core';
import { classes } from 'typestyle';
import { px } from 'csx';
import styles from '../Headerbar.styles';

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      top: px(2),
      right: px(-3),
      backgroundColor: (props: any) => props.color,
    },
    root: {
      // TODO: test to see if this style was even working dynamically
      // pass values from `containerStyle` in IconLink to StyledBadge
      // currently only used by the PlanLink component to limit size allocated when refresh available
      maxWidth: (props: any) => props.style?.maxWidth,
    },
  })
)(Badge);

const StyledPulsingBadge = withStyles(() =>
  createStyles({
    badge: {
      top: px(2),
      right: px(-4), // slightly increased "left-margin" for ripple animation than static badge
      // function allows for dynamic setting of color from props
      backgroundColor: (props) => props.color,
      color: (props) => props.color,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      } as CSSProperties,
    },
    root: {
      // pass values from `containerStyle` in IconLink to StyledBadge
      // currently only used by the PlanLink component to limit size allocated when refresh available
      maxWidth: (innerprops: any) => innerprops.style.maxWidth,
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
)(Badge);

interface IconLinkProps {
  id: string;
  text: string;
  iconClass: string;
  iconStyle?: React.CSSProperties;
  linkClass?: string;
  linkStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  errorBadge?: boolean;
  showBadge?: boolean;
  pulseBadge?: boolean;
  badgeColor?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

export const IconLink = forwardRef<null, IconLinkProps>(function IconLink(props, ref) {
  const {
    id,
    text,
    iconClass,
    iconStyle,
    linkClass,
    linkStyle,
    containerStyle,
    errorBadge = false,
    showBadge = false,
    pulseBadge = false,
    badgeColor,
    onClick,
    onMouseOver,
    onMouseLeave,
    tooltipText = '',
    tooltipPlacement,
  } = props;

  const link = (
    <Link
      id={id}
      component="button"
      className={classes(styles.iconLink, linkClass)}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      data-qa="IconLink"
      style={{ ...linkStyle }}
      ref={ref}
    >
      <Icon
        className={`${iconClass} ${styles.iconRightSpace}`}
        component="i"
        style={{ fontSize: 'small', ...iconStyle }}
      />
      <span>{text}</span>
    </Link>
  );

  const badgeWrappedLink = pulseBadge ? (
    <StyledPulsingBadge
      color={badgeColor ?? 'secondary'}
      variant="dot"
      invisible={!showBadge}
      style={{ ...containerStyle }}
      overlap="rectangular"
    >
      {link}
    </StyledPulsingBadge>
  ) : (
    <StyledBadge
      color={badgeColor ?? (errorBadge ? 'error' : 'secondary')}
      variant="dot"
      invisible={!showBadge}
      style={{ ...containerStyle }}
      overlap="rectangular"
    >
      {link}
    </StyledBadge>
  );

  return (
    <Tooltip title={tooltipText} arrow={true} placement={tooltipPlacement}>
      {badgeWrappedLink}
    </Tooltip>
  );
});
