import React, { Component } from 'react';
import { isNil, isEqual, get, isEmpty, first, memoize } from 'lodash';

import { Overlay } from 'src/common-ui/index';
import { WorklistProps, WorklistState } from 'src/pages/Worklist/Worklist.types';
import CompanionList from 'src/components/CompanionList/CompanionList';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import WorklistViewContainer from 'src/pages/Worklist/WorklistViewContainer';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { classes } from 'typestyle';
import { worklistCompanionViewContainer, worklistViewContainer } from './Worklist.styles';
import { FabType } from 'src/components/higherOrder/withFab';
import { Route, Routes } from 'react-router-dom';
import { GroupViewItem } from 'src/services/configuration/codecs/viewdefns/general';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';

export default class Worklist extends Component<WorklistProps, WorklistState> {
  constructor(props: WorklistProps) {
    super(props);

    this.state = {
      sortByDirection: 'desc',
      sortByDataIndex: '',
      useStyleColors: true,
      viewMounted: false,
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.setupDefaultSortBy();
    this.setState({
      viewMounted: true,
    });
  }

  componentDidUpdate(prevProps: WorklistProps) {
    if (!isEqual(this.props.styles, prevProps.styles) && this.props.config) {
      const { companionData } = this.generateCompanionData();
      const selectedCompanionItem =
        companionData.find((s) => s.id === this.props.selectedItemId) ?? first(companionData);

      if (!isNil(selectedCompanionItem) && selectedCompanionItem.id != this.props.selectedItemId) {
        this.props.selectItem(selectedCompanionItem.id);
      }
    }

    if (!isEqual(this.props.config, prevProps.config)) {
      this.setupDefaultSortBy();
    }
  }

  setupDefaultSortBy() {
    if (isEmpty(this.state.sortByDataIndex)) {
      this.setState({
        sortByDataIndex: this.props.config?.companionView?.sortBy.defaults.dataIndex ?? '',
      });
    }
  }

  generateCompanionData = () => {
    const { config, styles } = this.props;
    const { sortByDataIndex, sortByDirection } = this.state;
    const companionDataLookup = parseCompanionListViewConfig(
      (config?.companionView.itemMappings as unknown) as TenantConfigViewData
    );
    const companionData = companionDataParse(styles, companionDataLookup, sortByDirection, sortByDataIndex);
    return {
      companionData,
      companionDataLookup,
    };
  };

  onFabClick = () => {
    switch (this.props.fab.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  generateScrollTo = memoize(
    () => {
      const levelField = this.props.selectedLevel?.dataIndex;
      const selectedItemId = this.props.selectedItemId;
      return {
        eventId: Date.now(),
        where: {
          key: levelField ? `${levelField}:id` : 'id',
          value: selectedItemId,
        },
      };
    },
    () => {
      const levelField = this.props.selectedLevel?.dataIndex;
      const selectedItemId = this.props.selectedItemId;
      return `${levelField}-${selectedItemId}`;
    }
  );

  handleSortChange = (sortByDataIndex: string) => {
    this.setState({
      sortByDataIndex,
    });
  };

  handleSortDirectionChange = (sortByDirection: SortByDirection) => {
    this.setState({
      sortByDirection,
    });
  };

  handleLevelChange = (levelField: string) => {
    const { config } = this.props;
    const levelBys: GroupViewItem[] = get(config, 'companionView.levelBy.view', []);
    const selectedLevel = levelBys.find((i) => i.dataIndex === levelField);
    if (selectedLevel == null) return; // this is error state.
    if (selectedLevel.dataIndex !== this.props.selectedLevel?.dataIndex) {
      this.props.selectLevel(selectedLevel);
    }
  };

  render() {
    const {
      isLoading,
      config,
      subheaderSlice,
      subheaderViewDefns,
      styles,
      flowStatus,
      updateFlowStatus,
      flowStatusConfig,
      showFlowStatus,
      onShowView,
      updateCompanionSearchString,
      updateStyleItem,
      companionSearch,
      adornments,
      showUndoBtn,
      isPrintMode,
      hideCompanion,
      selectedLevel,
      selectedItemId,
    } = this.props;
    const { sortByDataIndex, sortByDirection } = this.state;

    // If we don't have config, there literally nothing to render.
    if (isNil(config)) {
      return <Overlay type="loading" visible={true} />;
    }

    if (!this.state.viewMounted) {
      return <div />;
    }

    const defaultTabIndex = config.tabs.findIndex((tab) => (tab.componentType as string) === config.defaultTab);
    const { companionData, companionDataLookup } = this.generateCompanionData();
    const selectedCompanionItem = companionData.find((item) => item.id === selectedItemId);
    const selectedListDataItem = selectedItemId
      ? styles.find((s) => s[companionDataLookup.titleId] == selectedItemId)
      : undefined;
    const scrollTo = this.generateScrollTo();

    const mainView = (
      <WorklistViewContainer
        adornments={adornments}
        defaultTabIndex={defaultTabIndex >= 0 ? defaultTabIndex : 0}
        // @ts-ignore
        tabsConfig={config.tabs}
        selectedCompanionItem={selectedCompanionItem}
        selectedListDataItem={selectedListDataItem}
        subheaderSlice={subheaderSlice}
        subheaderViewDefns={subheaderViewDefns}
        styles={styles}
        onViewRefresh={onShowView}
        onStyleItemUpdate={updateStyleItem}
        showUndoBtn={showUndoBtn}
        isPrintMode={isPrintMode}
      />
    );
    return (
      <div className={classes(listPairStyle, worklistViewContainer(isPrintMode))}>
        <Overlay type="loading" visible={isLoading || isNil(config)} />
        <div className="data-container" data-qa="worklist-view">
          {/* `hideCompanion` implemented in css, as otherwise it interferes with the data fetch */}
          <div className={worklistCompanionViewContainer(hideCompanion)}>
            <CompanionList
              config={config.companionView}
              selectedItemId={selectedItemId ?? null}
              data={companionData}
              dataLookup={companionDataLookup}
              isDataloaded={!isLoading}
              renderSearchComponent={true}
              renderFilterComponent={showFlowStatus}
              sortDirection={sortByDirection}
              sortField={sortByDataIndex}
              levelField={selectedLevel?.dataIndex}
              search={companionSearch}
              flowStatus={flowStatus}
              flowStatusOptions={flowStatusConfig}
              scrollTo={scrollTo}
              onSearchChange={updateCompanionSearchString}
              onItemSelect={this.props.selectItem}
              onLevelChange={this.handleLevelChange}
              onSortChange={this.handleSortChange}
              onSortDirectionChange={this.handleSortDirectionChange}
              onFilterChange={updateFlowStatus}
            />
          </div>
          <Routes>
            {config.tabs.map((tab) => (
              <Route key={tab.pathSlot} path={`${tab.pathSlot}`} element={mainView} />
            ))}
          </Routes>
        </div>
      </div>
    );
  }
}
