import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Powerdriver } from './AssortmentCart';
import { CartItem } from './AssortmentCart.types';
import { ConfigApiV2, ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import service from 'src/ServiceContainer';
import { AppThunkDispatch, AppState } from 'src/store';
import { generateCart, getAllColors, getAllPowerdrivers } from '../AssortmentBuild/AssortmentAdd/Assortment.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isNil } from 'lodash';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

interface AssortmentAddButton {
  buttons: {
    label: string;
    hashRoute: string;
  };
  showSpecialAdd?: boolean;
}
interface SensitiveLifecycleModal {
  lifecycleReference: {
    title: string;
    lifecycleConfig: ConfigApiV2;
    storeConfig: ConfigApiV2;
    dataApiLifecycle: ClientDataApi;
    dataApiStore: ClientDataApi;
    dependentsApi: ClientDataApi | undefined;
  };
}
// zodify this
export interface AssortmentAddViewDefn extends AssortmentAddButton, SensitiveLifecycleModal {}
interface CartInfo {
  cart_id: string;
}
export interface AssortmentCartSlice {
  cartItems: CartItem[];
  cartItemsToAddCount: number;
  colors: string[];
  powerdrivers: Powerdriver[];
  cartInfo: CartInfo | null;
  viewDefn: AssortmentAddViewDefn | null;
}

const initialState: AssortmentCartSlice = {
  cartItems: [],
  colors: [],
  powerdrivers: [],
  cartItemsToAddCount: 0,
  cartInfo: null,
  viewDefn: null,
};


const addStylesReducer = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    resetAssortmentCart: () => initialState,
    clearCartLocalState(state) {
      state.cartInfo = null;
      state.powerdrivers = [];
      state.colors = [];
      state.viewDefn = null;
    },
    clearCartItems: (state) => {
      state.cartItems = [];
    },
    setCartInfo(state, action: PayloadAction<CartInfo>) {
      state.cartInfo = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    receiveViewDefn(state, action: PayloadAction<AssortmentAddViewDefn>) {
      state.viewDefn = action.payload;
    },
    receiveColors(state, action: PayloadAction<string[]>) {
      state.colors = action.payload;
    },
    receivePowerdrivers(state, action: PayloadAction<Powerdriver[]>) {
      state.powerdrivers = action.payload;
    },
  },
});

// As or right now this is one of two places where the actions are handled via lenses.
// SelectStyles is built in a similar way.
export const {
  resetAssortmentCart,
  clearCartLocalState,
  clearCartItems,
  receiveError,
  setCartInfo,
  receiveViewDefn,
  receiveColors,
  receivePowerdrivers,
} = addStylesReducer.actions;
export default addStylesReducer.reducer;
function parsePowerdriversToSubheader(data: BasicPivotItem[]): Powerdriver[] {
  return data
    .map((powerdriver) => {
      return {
        id: powerdriver.id,
        dataIndex: powerdriver.id,
        text: powerdriver.name,
        style: powerdriver['attribute:conceptstyleid:name'],
        styleColor: powerdriver['attribute:conceptstylecolorid:name'],
      };
    })
    .filter((transformedPD) => !isNil(transformedPD.style));
}
export function initCart(defnId: string, department: string) {
  return async (dispatch: AppThunkDispatch, _getState: () => AppState) => {
    try {
      // force wait all on successful cart config fetch
      await service.tenantConfigClient
        .getTenantViewDefn<AssortmentAddViewDefn>({
          defnId,
        })
        .then((confResp) => {
          // we override the dataApis for the lifecycle/ranging
          return dispatch(receiveViewDefn(confResp));
        });
      const fetchColorsDispatch = getAllColors().then((res) => {
        return dispatch(receiveColors(res));
      });
      const fetchPowerdriverDispatch = getAllPowerdrivers(department).then((res) => {
        return dispatch(receivePowerdrivers(parsePowerdriversToSubheader(res)));
      });
      const fetchCartInfoDispatch = generateCart().then((info) => {
        return dispatch(setCartInfo(info));
      });
      return Promise.all([fetchColorsDispatch, fetchPowerdriverDispatch, fetchCartInfoDispatch]);
    } catch (e) {
      service.loggingService.error(e as any);
      return dispatch(receiveError(e as any));
    }
  };
}
