import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { Renderer } from 'src/utils/Domain/Renderer';
import { GridItem, listDataTreeToAgFlatTree } from 'src/utils/Component/AgGrid/AgDataFormat';
import { LoadingProjection, StateProjection } from 'src/components/MacroGridPair/MacroGridPair';
import { filterAndSortPivotItems } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { Metric, MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { parseConfigureConfig } from 'src/components/Configure/Configure';
import { Option } from 'src/components/Configure/ConfigureModal';
import { parseGridConfig } from 'src/utils/Component/AgGrid/AgConfigParse';
import { DefaultShownValues } from 'src/common-ui/components/DataGrid/DataGrid';
import { get, isEmpty } from 'lodash';
import { ViewDataState } from 'src/types/Domain';

export interface ViewDefns {
  grid: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  macro: TenantConfigViewData;
  configure?: TenantConfigViewData;
}

export interface StateSelection {
  title: string;
  aggBys?: string[];
  defaultShownValues?: DefaultShownValues;
  viewDefns?: ViewDefns;
  configLoaded: boolean;
  gridDataLoaded: boolean;
  macroDataLoaded: boolean;
  dataLoaded: boolean;
  gridData: BasicPivotItem[];
  macroData: BasicPivotItem[];
  subheaderState: SubheaderSlice;
  viewDataState?: ViewDataState | ViewDataState[];
}

const defaultFormatter = (n: number) => (n === undefined ? '' : n.toLocaleString());

const ARROW_PERCENT = 'arrowPercent';
export function macroConfigItemToMetric(
  macroConfigItem: TenantConfigViewItem,
  data: BasicPivotItem
): Metric {
  const formatter = (macroConfigItem.renderer && Renderer[macroConfigItem.renderer]) || defaultFormatter;
  const dataItem = data[macroConfigItem.dataIndex] || 0;

  let metric: Metric = {
    rendered: formatter(dataItem),
    label: macroConfigItem.text,
  };
  if (macroConfigItem.xtype === ARROW_PERCENT) {
    if (dataItem > 0) {
      metric = {
        ...metric,
        direction: 'up',
      };
    } else if (dataItem < 0) {
      metric = {
        ...metric,
        direction: 'down',
      };
    }
  }
  return metric;
}

export function projectState(stateSelection: StateSelection): StateProjection {
  const {
    title,
    configLoaded,
    gridDataLoaded,
    macroDataLoaded,
    dataLoaded,
    gridData,
    macroData,
    subheaderState,
    viewDefns,
    aggBys,
    viewDataState,
  } = stateSelection;

  const { search, sortBy } = subheaderState;

  if (configLoaded && viewDefns && !isEmpty(viewDefns.grid)) {
    // Config
    const { rowHeight, colDefs, treeColumnDefinition, frameworkComponents, stylePaneTriggerSet, defaultShownValues } =
      parseGridConfig(viewDefns.grid);

    let configureOptionGroups;
    const actualColDefs = colDefs;
    let actualTreeColumnDefinition = treeColumnDefinition;
    let defaultConfigureSelections: Option[] = [],
      originalDefaultConfigureSelections;
    let configureInstructions;
    let gridItems: GridItem[] = [];
    let minimumSelections;

    if (viewDefns.configure) {
      const parseResult = parseConfigureConfig(viewDefns.configure);
      configureOptionGroups = parseResult.optionGroups;
      defaultConfigureSelections = parseResult.defaultSelections;
      minimumSelections = parseResult.minimumSelections;
      originalDefaultConfigureSelections = parseResult.originalDefaultSelections;
    }
    const configSearchOrDefafult = get(viewDefns, 'grid.searchIndexes', externalGridSearchFields) as string[];

    if (aggBys && aggBys.length) {
      // GroupBy supercedes config passed treeColumns
      if (treeColumnDefinition) {
        actualColDefs.push(treeColumnDefinition);
      }
      const result = listDataTreeToAgFlatTree(aggBys, search, [], gridData, false, configSearchOrDefafult);
      actualTreeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      gridItems = filterAndSortPivotItems(search, sortBy, configSearchOrDefafult, [], gridData);
    }

    const macros: MacrosProps[] = viewDefns.macro.view.map((macroConfigItem) => {
      const viewArray = macroConfigItem.view || [];

      const dataItem: BasicPivotItem = get(macroData, '[0]', {} as BasicPivotItem);
      const primary = macroConfigItemToMetric(macroConfigItem, dataItem);
      const subMetrics = viewArray.map((metric) => {
        return macroConfigItemToMetric(metric, dataItem);
      });

      return {
        dataLoaded: macroDataLoaded,
        metrics: {
          primary,
          subMetrics,
        },
        extraClasses: '',
      };
    });

    const projection: StateProjection = {
      title,
      configLoaded,
      gridDataLoaded,
      macroDataLoaded,
      dataLoaded,
      rowHeight,
      defaultShownValues,
      gridData: gridItems,
      colDefs,
      frameworkComponents,
      treeColumnDefinition: actualTreeColumnDefinition,
      stylePaneTriggerSet,
      macros,
      configureInstructions,
      minimumSelections: minimumSelections,
      defaultConfigureSelections,
      originalDefaultConfigureSelections,
      configureOptionGroups,
      viewDefn: viewDefns.grid,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      viewDataState,
    };
    return projection;
  }

  const loadingProjection: LoadingProjection = {
    title,
    configLoaded: false,
    gridDataLoaded: false,
    macroDataLoaded: false,
    dataLoaded: false,
    viewDataState,
  };

  return loadingProjection;
}
