import React, { useRef, useState } from 'react';
import { Divider, Popover, Typography } from '@material-ui/core';
import { important, px } from 'csx';
import { AddToAssortmentItem, PlanItem } from 'src/pages/AssortmentBuild/Planning.types';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import styles from 'src/components/Headerbar/Headerbar.styles';
import { getIconBadgeColor, parsePlanQueueItems } from 'src/components/PlanQueue/PlanQueue.utils';

const PlanningLinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(145)),
};

const LinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(115)),
};
const iconStyle: React.CSSProperties = {
  color: '#00a89b',
};
const refreshIconStyle: React.CSSProperties = {
  transform: 'scaleX(-1)',
  color: '#00a89b',
};
const iconStatusContainer: React.CSSProperties = {
  position: 'relative',
  left: 8,
};

interface PlanPopoverContentProps {
  addProcessing: number;
  addFailed: number;
  planPending: number;
  planProcessing: number;
  planCompleted: number;
  planFailed: number;
}

const PlanPopoverContent = ({
  addProcessing,
  addFailed,
  planPending,
  planProcessing,
  planCompleted,
  planFailed,
}: PlanPopoverContentProps) => {
  return (
    <div style={{ minWidth: 325 }}>
      <Typography>Status</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-around', margin: '0.5rem 0' }}>
        <section>
          <Typography variant="overline">Planning</Typography>
          <ul>
            <li>
              <div className="list-popover-container">
                <div>Pending: {planPending}</div>
              </div>
            </li>
            <li>
              <div className="list-popover-container">
                <div>Processing: {planProcessing}</div>
              </div>
            </li>
            <li>
              <div className="list-popover-container">
                <div>Completed: {planCompleted}</div>
              </div>
            </li>
            <li>
              <div className="list-popover-container">
                <div>Failed: {planFailed}</div>
              </div>
            </li>
          </ul>
        </section>
        <Divider orientation="vertical" flexItem={true} />
        <section>
          <Typography variant="overline">Add to Assortment</Typography>
          <ul>
            <li>
              <div className="list-popover-container">
                <div>Processing: {addProcessing}</div>
              </div>
            </li>
            <li>
              <div className="list-popover-container">
                <div>Failed: {addFailed}</div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

interface PlanLinkProps {
  askForRefresh: boolean;
  planItems: PlanItem[];
  addToAsstItems: AddToAssortmentItem[];
  onClick: () => void;
  onRefresh: () => void;
}

export const PlanLink = ({ addToAsstItems, askForRefresh, planItems, onClick, onRefresh }: PlanLinkProps) => {
  const anchorElement = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
    onClick();
  };
  const handleMouseOver = () => setOpen(true);
  const handleMouseLeave = () => setOpen(false);

  const { planPending, planProcessing, planCompleted, planFailed, addProcessing, addFailed } = parsePlanQueueItems(
    planItems,
    addToAsstItems
  );
  const planInProgress = planProcessing.length > 0;
  const addToAsstInProgress = addProcessing.length > 0;

  // TODO: turning off enhanced behavior of badge until behavior requested
  const showBadge = planPending.length > 0;
  // show badge for plan items pending/processing/failed and addToAsst processing/failed
  // const showBadge =
  //   planPending.length > 0 ||
  //   planProcessing.length > 0 ||
  //   planCompleted.length > 0 ||
  //   planFailed.length > 0 ||
  //   addProcessing.length > 0 ||
  //   addFailed.length > 0;

  // TODO: turning off displaying error badge until behavior requested
  // show error (red) badge for plan items failed and addToAsst failed
  const errorBadge = planFailed.length > 0 || addFailed.length > 0;

  // TODO: turning off pulse until behavior requested
  const pulseBadge = false;
  // pulse badge for planning/add in progress
  // const pulseBadge = planProcessing.length > 0 || addProcessing.length > 0;

  const badgeColor = getIconBadgeColor({
    addProcessing,
    addFailed,
    planPending,
    planProcessing,
    planCompleted,
    planFailed,
  });

  return (
    <React.Fragment>
      <span style={iconStatusContainer}>
        {askForRefresh ? (
          <IconLink
            ref={anchorElement}
            id="PlanRefreshReady"
            iconClass="fa fa-undo"
            linkClass="clickable refreshable"
            containerStyle={LinkContainerStyle}
            text=""
            iconStyle={refreshIconStyle}
            onClick={onRefresh}
            tooltipText="Refresh your view to get the latest plan data"
          />
        ) : (
          <IconLink
            ref={anchorElement}
            id="PlanStatus"
            iconClass={!(planInProgress || addToAsstInProgress) ? 'fa fa-check-circle' : 'fa fa-spinner fa-spin'}
            containerStyle={PlanningLinkContainerStyle}
            iconStyle={iconStyle}
            text=""
            tooltipText={
              !(planInProgress || addToAsstInProgress)
                ? 'Latest plan data is displayed'
                : 'Plan items are being processed'
            }
          />
        )}
      </span>
      <IconLink
        id="PlanLink"
        iconClass={'fa fa-paper-plane'}
        containerStyle={PlanningLinkContainerStyle}
        text={'Plan Queue'}
        showBadge={showBadge}
        errorBadge={errorBadge}
        pulseBadge={pulseBadge}
        badgeColor={badgeColor}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={anchorElement}
      />
      <Popover
        open={isOpen}
        classes={{ root: styles.filterPopover }}
        anchorEl={anchorElement.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        container={anchorElement.current}
        PaperProps={{
          onMouseEnter: handleMouseOver,
          onMouseLeave: handleMouseLeave,
        }}
        disableRestoreFocus={true}
      >
        <PlanPopoverContent
          addFailed={addFailed.length}
          addProcessing={addProcessing.length}
          planPending={planPending.length}
          planProcessing={planProcessing.length}
          planCompleted={planCompleted.length}
          planFailed={planFailed.length}
        />
      </Popover>
    </React.Fragment>
  );
};
