import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Avatar, Tooltip, Tabs, Tab, LinearProgress } from '@material-ui/core';
import { AppState } from 'src/store';
import styles, { usePlanQueueStyles } from './PlanQueue.styles';
import { AddToAssortmentItem, MinimalItem, PlanItem } from 'src/pages/AssortmentBuild/Planning.types';
import { PlanningTabSection } from './PlanningTabSection';
import { formatAsstTime, getIconBadgeColor, processPlanQueueItems } from './PlanQueue.utils';

interface PlanQueueTabLabelProps {
  itemCount: number;
  text: string;
  tooltipText: string;
  badgeColor: string;
}

const PlanQueueTabLabel = ({ itemCount, tooltipText, text, badgeColor }: PlanQueueTabLabelProps) => {
  const planQueueStyles = usePlanQueueStyles(badgeColor)();
  return (
    <div className={styles.titleText}>
      <Tooltip title={tooltipText} placement="top-start" arrow>
        <Avatar className={planQueueStyles.small} variant="square">
          <span>{itemCount}</span>
        </Avatar>
      </Tooltip>
      <span>{text}</span>
    </div>
  );
};

function PlanTime(item: MinimalItem | undefined) {
  return <span>{moment(`${item?.updatedAt}`).format('h:mm a')}</span>;
}

function AsstTime(item: MinimalItem | undefined) {
  return <span>{formatAsstTime(item?.updatedAt ?? '')}</span>;
}

interface PlanQueueProps {
  planItems: PlanItem[];
  addToAsstItems: AddToAssortmentItem[];
}

const PlanQueue = ({ planItems, addToAsstItems }: PlanQueueProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [search, setSearch] = useState('');

  const handleTabChange = (_event: unknown, selectedIndex: number) => {
    setSelectedTabIndex(selectedIndex);
  };

  const handleSearchItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredPlanItems = planItems.filter((pi) => pi.description.toLowerCase().includes(search.toLowerCase()));
  const { processedPlanData, processedCartData } = processPlanQueueItems(filteredPlanItems, addToAsstItems);
  const addItemsCount = processedCartData.processing.items.length + processedCartData.failed.items.length;

  const planBadgeColor = getIconBadgeColor({
    planPending: processedPlanData.pending.items,
    planProcessing: processedPlanData.processing.items,
    planCompleted: processedPlanData.completed.items,
    planFailed: processedPlanData.failed.items,
    cssColorFormat: true,
  });
  const cartBadgeColor = getIconBadgeColor({
    addProcessing: processedCartData.processing.items,
    addFailed: processedCartData.failed.items,
    cssColorFormat: true,
  });

  return (
    <div className={styles.container}>
      <Tabs value={selectedTabIndex} onChange={handleTabChange} indicatorColor="secondary" variant="fullWidth">
        <Tab
          label={
            <PlanQueueTabLabel
              text={'Plan Status'}
              tooltipText={`Plan status for ${planItems.length} item(s) available.`}
              itemCount={planItems.length}
              badgeColor={planBadgeColor}
            />
          }
        />
        <Tab
          label={
            <PlanQueueTabLabel
              text={'Cart Status'}
              tooltipText={`Processing: ${processedCartData.processing.items.length}, Failed: ${processedCartData.failed.items.length}`}
              itemCount={addItemsCount}
              badgeColor={cartBadgeColor}
            />
          }
        />
      </Tabs>

      {/* Planning Tab content */}
      {selectedTabIndex === 0 ? (
        <div>
          <div className={styles.searchContainer}>
            <input type="text" placeholder="Search item by description" value={search} onChange={handleSearchItem} />
          </div>
          <section className={styles.itemsContainer}>
            <PlanningTabSection
              items={processedPlanData.pending.items}
              sectionName={'Pending'}
              secondaryList={() => <LinearProgress variant={'indeterminate'} />}
              componentType={{ component: 'div' }}
              badgeColor={processedPlanData.pending.badgeColor}
            />
            <PlanningTabSection
              items={processedPlanData.processing.items}
              sectionName={'Processing'}
              secondaryList={() => <LinearProgress variant={'buffer'} value={0} valueBuffer={0} />}
              componentType={{ variant: 'caption' }}
              badgeColor={processedPlanData.processing.badgeColor}
            />
            <PlanningTabSection
              items={processedPlanData.completed.items}
              sectionName={'Completed'}
              secondaryList={PlanTime}
              componentType={{ variant: 'caption' }}
              badgeColor={processedPlanData.completed.badgeColor}
            />
            <PlanningTabSection
              items={processedPlanData.failed.items}
              sectionName={'Failed'}
              secondaryList={PlanTime}
              componentType={{ variant: 'caption' }}
              badgeColor={processedPlanData.failed.badgeColor}
            />
          </section>
        </div>
      ) : null}

      {/* Cart Tab content */}
      {selectedTabIndex === 1 ? (
        <div>
          <div className={styles.searchContainer}>
            {/* Placeholder for search input should it be required, for now container just adds vertical spacing between section/tab */}
            {/* <input type="text" placeholder="Search item by description" value={search} onChange={handleSearchItem} /> */}
          </div>
          <section className={styles.itemsContainer}>
            <PlanningTabSection
              items={processedCartData.processing.items}
              sectionName={'Processing'}
              componentType={{ component: 'span' }}
              badgeColor={processedCartData.processing.badgeColor}
            />
            <PlanningTabSection
              items={processedCartData.failed.items}
              sectionName={'Failed'}
              componentType={{ variant: 'caption' }}
              secondaryList={AsstTime}
              badgeColor={processedCartData.failed.badgeColor}
            />
          </section>
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state: AppState): PlanQueueProps {
  const { planItems, addToAsstItems } = state.planTracker;
  return {
    planItems,
    addToAsstItems,
  };
}

export default connect(mapStateToProps)(PlanQueue);
