import { style, extend, media } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { containerStyle, SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { form, selectorHeight } from 'src/utils/Style/Dropdown.styles';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { important, px } from 'csx';
import { ChartOptions } from 'highcharts';

export const selectedItemColor = TEAL_PRIMARY;

export const heatChartItemSizeDefault = 63;
export const heatChartStyle: ChartOptions = {
  marginTop: 110, // 110 in order to give extra space to render long region names, specifcally "E-COMMERCE"
  marginBottom: 5,
  marginLeft: 100,
  marginRight: 75,
  plotBorderWidth: 1,
};
export const chartStyle = style({
  width: '100%',
  height: '100%',
  $nest: {
    '& .highcharts-root': {
      overflow: important('visible'),
    },
    '& .highcharts-container svg': {
      overflow: important('visible'),
    },
    '& .highcharts-container': {
      width: important('102%'),
    },
  },
});

export const colorStops: [number, string][] = [
  [0, '#fcfed3'],
  [0.25, '#d7efb6'],
  [0.5, '#7ccaba'],
  [0.75, '#339ebe'],
  [1, '#2859a1'],
];

export const heatMapBorderColor = '#e4e4e4';
const trendingArrow: NestedCSSProperties = {
  display: 'inline-block',
  borderLeft: 'solid 6px transparent',
  borderRight: 'solid 6px transparent',
  marginRight: '1px',
  float: 'right',
  top: '50%',
};
const trendingDown = style(
  extend(trendingArrow, {
    borderTop: 'solid 8px #c20c09',
  })
);
const trendingUp = style(
  extend(trendingArrow, {
    borderBottom: 'solid 8px #318310',
    color: '#e07d31',
  })
);

const chart = style({
  $debugName: 'simple-chart-chart',
  height: `calc(100% - ${selectorHeight})`,
});

const gridRow = 'grid-row';
const chartHeader = 'chart-header';
const gridHeaderRow = 'grid-header-row';
const ruler = 'header-ruler';

const componentContainer = style({
  width: '100%',
  height: 'calc(100% - 1px)',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    [`.${containerStyle}`]: {
      minWidth: '100%',
    },
    ' .content-container': {
      height: '99%',
      minHeight: `calc(100% - ${px(SUBHEADER_HEIGHT + 11)})`,
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

const gridContainer = style({
  $debugName: 'grid-container',
  width: '34%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
});
const heatChartContainer = style(media({ minWidth: 1025 }, { height: `calc(100% - ${selectorHeight})` }), {
  $debugName: 'heatChartContainer',
  height: '100%',
  width: '66%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    [`.${form}`]: {
      alignSelf: 'end',
      marginRight: 25,
    },
  },
});
const fullWidthHalfHeightGridItem = style(media({ minWidth: 1025 }, { height: '50%' }), {
  $debugName: 'fullWidthHalfHeightGridItem',
  width: '100%',
});
const dropdownContainer = style({
  alignSelf: 'end',
  marginRight: 25,
});

export default {
  gridRow,
  chartHeader,
  gridContainer,
  componentContainer,
  ruler,
  chart,
  gridHeaderRow,
  trendingDown,
  trendingUp,
  fullWidthHalfHeightGridItem,
  heatChartContainer,
  dropdownContainer,
};
