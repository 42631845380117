import React from 'react';
import { Avatar, Divider, ListItem, ListItemText } from '@material-ui/core';
import styles from './PlanQueue.styles';
import { MinimalItem } from 'src/pages/AssortmentBuild/Planning.types';
import { Accordion, AccordionSummary, Tooltip, AccordionDetails } from '@material-ui/core';
import { List, AutoSizer } from 'react-virtualized';
import { usePlanQueueStyles } from './PlanQueue.styles';

const ROW_HEIGHT = 60;

interface PlanningTabSectionProps {
  items: MinimalItem[];
  componentType?: {
    [key: string]: string;
  };
  sectionName: string;
  secondaryList?: (item?: MinimalItem) => JSX.Element;
  badgeColor: string;
}

export const PlanningTabSection = (props: PlanningTabSectionProps) => {
  const { items, componentType = {}, secondaryList, sectionName, badgeColor } = props;
  const itemsCount = items.length;
  const totalSectionHeight = itemsCount * ROW_HEIGHT;
  const planQueueStyles = usePlanQueueStyles(badgeColor, totalSectionHeight)();

  return (
    <React.Fragment>
      <section className={styles.item}>
        <Accordion square className={planQueueStyles.accordionContainer}>
          <div className={styles.itemTitle}>
            <Tooltip title={itemsCount} placement="top-start" arrow>
              <Avatar className={planQueueStyles.small} variant="square">
                <span>{itemsCount > 99 ? '99+' : itemsCount}</span>
              </Avatar>
            </Tooltip>
            <AccordionSummary
              className={planQueueStyles.accordionSummary}
              expandIcon={<i className="fa fa-chevron-down"></i>}
            >
              <span className={planQueueStyles.medium}>{sectionName}</span>
            </AccordionSummary>
          </div>
          <AccordionDetails>
            <div className={planQueueStyles.accordionDetailsList}>
              {/* List's noRowsRenderer is never triggered, manually falling back instead */}
              {itemsCount === 0 ? (
                <ListItem>
                  <ListItemText secondary={`No ${sectionName} items`} />
                </ListItem>
              ) : (
                <AutoSizer>
                  {({ width }) => (
                    <List
                      width={width}
                      height={totalSectionHeight >= 500 ? 500 : totalSectionHeight}
                      overscanRowCount={10}
                      rowCount={items.length}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={({ index, key, style }) => {
                        const item = items[index];
                        const primaryText = item.primaryText;
                        return (
                          <div key={key} style={style}>
                            <Tooltip title={primaryText} placement="top-start">
                              <ListItem>
                                <ListItemText
                                  primary={primaryText}
                                  secondary={secondaryList ? secondaryList(item) : null}
                                  primaryTypographyProps={{ gutterBottom: true, variant: 'subtitle2', noWrap: true }}
                                  secondaryTypographyProps={componentType}
                                />
                              </ListItem>
                            </Tooltip>
                          </div>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </section>
    </React.Fragment>
  );
};
