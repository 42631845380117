import React from 'react';
import { classes } from 'typestyle';
import Skeleton from '@material-ui/lab/Skeleton';
import { macroSummaryStyle } from 'src/common-ui/components/Macros/MacroSummary/MacroSummary.styles';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { getDirectionClasses } from 'src/common-ui/components/Macros/Macros.utils';

export default function MacroSummary({ dataLoaded, metrics, extraClasses }: MacrosProps) {
  const { primary, subMetrics } = metrics;
  const containerClass = classes('macro-summary', macroSummaryStyle, extraClasses ? extraClasses : '');
  if (!dataLoaded) {
    return (
      <div className={containerClass}>
        <div className="left-container">
          <header>{primary.label}</header>
          <Skeleton className="primary-skeleton" variant="text" animation="wave" />
        </div>
        <div className="right-container">
          {subMetrics.map((metric) => {
            return (
              <section key={metric.label} className="variance">
                <label className="metric-name">{metric.label}</label>
                <Skeleton className="icon-skeleton" variant="text" animation="wave" />
                <Skeleton className="secondary-skeleton" variant="text" animation="wave" />
              </section>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={containerClass}>
      <div className="left-container">
        <header>{primary.label}</header>
        <div className="primary-metric">{primary.rendered}</div>
      </div>
      <div className="right-container">
        {subMetrics.map((metric) => {
          const { iconClass } = getDirectionClasses(metric.direction);
          const hiddenIconClass = classes('none', iconClass);

          return (
            <section key={metric.label} className="variance">
              <label className="metric-name">{metric.label}</label>
              <i className={metric.direction ? iconClass : hiddenIconClass} />
              <span className="metric-value">{metric.rendered}</span>
            </section>
          );
        })}
      </div>
    </div>
  );
}
