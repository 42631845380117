import React from 'react';
import { classes } from 'typestyle';
import { Overlay } from 'src/common-ui';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import {
  DataGridProps,
  ScrollTo as GridScrollTo,
  DefaultShownValues,
} from 'src/common-ui/components/DataGrid/DataGrid';
import Subheader from 'src/components/Subheader/Subheader.container';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import { ColDef, CellClassParams, GridApi, GridReadyEvent, ColumnApi, ColumnState } from '@ag-grid-community/core';

import ConfigureModal, { ConfigureModalProps, OptionGroup, Option } from 'src/components/Configure/ConfigureModal';
import { GridItem, GroupHeaderKey } from 'src/utils/Component/AgGrid/AgDataFormat';
import { macroGridStyle, containerPrintMode, dataContainerStyle } from 'src/components/MacroGridPair/MacroGrid.styles';
import { FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import * as Lodash from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { multiHeaderDecorate } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/NestedHeader';
import { omitColDefMutations } from '../ListGridPair/ListGridPair';
import { ViewDataState } from 'src/types/Domain';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { MacroSummariesAccordion } from 'src/common-ui/components/Macros/MacroSummary/MacroSummariesAccordion';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import { find, isEmpty, isNil } from 'lodash';

export interface LoadingProjection {
  title: string;
  configLoaded: false;
  gridDataLoaded: false;
  macroDataLoaded: false;
  dataLoaded: false;
  viewDataState?: ViewDataState | ViewDataState[];
}

export interface LoadedProjection {
  title: string;
  configLoaded: boolean;
  gridDataLoaded: boolean;
  macroDataLoaded: boolean;
  dataLoaded: boolean;
  viewDataState?: ViewDataState | ViewDataState[];
  defaultShownValues: DefaultShownValues;
  rowHeight?: number;
  groupByDefn?: string;
  gridData: GridItem[];
  colDefs: ColDef[];
  frameworkComponents: FrameworkComponents;
  stylePaneTriggerSet: Set<string>;
  macros: MacrosProps[];
  treeColumnDefinition: ColDef | undefined;
  defaultConfigureSelections: Option[];
  configureOptionGroups?: OptionGroup[];
  configureInstructions?: string;
  minimumSelections?: number;
  configureSelections?: Option[];
  originalDefaultConfigureSelections?: Option[];
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
}

export type StateProjection = LoadedProjection | LoadingProjection;

export type Props = StateProjection &
  PrintProps & {
    title?: string;
    showFlowStatus?: boolean;
    subheaderErrorText?: string;
    showConfigure?: boolean;
    fullHeight?: boolean;
    onShowView(): void;
    onConfigAggregate?(aggBys: string[]): void;
    onUpdate?(): void;
    onConfigUpdate(config: TenantConfigViewData): void;
    updateConfigureSelections?(selections: Option[]): void;
  };

export interface State {
  gridScrollTo?: GridScrollTo;
  isMacroExpanded: boolean;
  configureIsOpen: boolean;
  configureLastSelected?: Option[];
}

export default class MacroGridPair extends React.Component<Props, State> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  filters: ColumnState[] | undefined;
  constructor(props: Props) {
    super(props);

    this.state = {
      isMacroExpanded: true,
      configureIsOpen: false,
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.dataLoaded && prevProps.dataLoaded) {
      const prevColDefs = omitColDefMutations(prevProps.colDefs);
      const nextColDefs = omitColDefMutations(this.props.colDefs);
      if (
        !Lodash.isEqual(nextColDefs, prevColDefs) &&
        Lodash.isEqual(prevProps.configureSelections, this.props.configureSelections)
      ) {
        this.updateColDefs();
      }
    }

    if (this.filters) {
      this.columnApi.applyColumnState({ state: this.filters });
    }
  }

  toggleSummary = () => {
    this.setState((state) => ({
      isMacroExpanded: !state.isMacroExpanded,
    }));
  };

  updateColDefs() {
    if (this.props.dataLoaded !== true || this.gridApi == null) return;
    this.gridApi.setColumnDefs(multiHeaderDecorate(this.props.colDefs));
  }

  updateConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections != null) {
      this.props.updateConfigureSelections(selections);
    }
  };

  // TODO: this is currently not called in NestedAttribute on configure selections apply
  applyConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections != null) {
      this.props.updateConfigureSelections(selections);
    }
    if (this.props.onConfigAggregate) {
      const aggBys = selections.map((sel) => sel.dataIndex);
      this.props.onConfigAggregate(aggBys);
    }
    this.setState(
      {
        configureLastSelected: selections,
      },
      () => this.updateColDefs() // this is where the coldefs are refreshed
    );
  };

  onConfigUpdate = (config: TenantConfigViewData) => {
    this.props.onConfigUpdate(config);
  };

  render() {
    const { title, showFlowStatus, subheaderErrorText, viewDataState, isPrintMode } = this.props;
    const containerClass = classes(macroGridStyle, isPrintMode ? containerPrintMode : '');
    const subheaderProps: SubheaderOwnProps = {
      title,
      showFlowStatus: !Lodash.isNil(showFlowStatus) ? showFlowStatus : true,
      showSearch: true,
      errorCondition: subheaderErrorText,
      viewDataState,
    };

    if (!this.props.configLoaded) {
      return (
        <div className={containerClass}>
          <Subheader {...subheaderProps} />
          <div className="macro-container">
            <Overlay type="loading" visible={true} />
          </div>
        </div>
      );
    }
    const {
      gridDataLoaded,
      macroDataLoaded,
      colDefs,
      rowHeight,
      macros,
      frameworkComponents,
      treeColumnDefinition,
      defaultConfigureSelections = [],
      configureOptionGroups,
      configureInstructions,
      minimumSelections,
      onConfigAggregate,
      defaultShownValues,
      showConfigure,
      viewDefn,
      unmodifiedViewDefn,
      originalDefaultConfigureSelections,
      gridData,
    } = this.props;
    const configureSelections =
      this.props.configureSelections != null ? this.props.configureSelections : defaultConfigureSelections;
    subheaderProps.viewConfigurator = {
      viewConfig: viewDefn,
      getColumnApi: () => {
        if (this.columnApi) {
          return this.columnApi;
        }
        return;
      },
      unmodifiedViewDefn,
      configureSelections,
      defaultConfigureSelections: originalDefaultConfigureSelections,
      updateConfig: this.onConfigUpdate,
      updateConfiguration: this.applyConfigureSelections,
      showPinCheckboxForGrid: true,
    };
    const { isMacroExpanded, configureIsOpen, configureLastSelected = configureSelections } = this.state;

    const treeCol = treeColumnDefinition;
    if (treeCol) {
      treeCol.cellRendererParams = {
        suppressCount: true,
        checkbox: false,
      };

      treeCol.valueGetter = (params) => {
        const group = params.data.group;
        let fieldValue = group[group.length - 1];

        const colDefGroup = find(colDefs, { headerName: 'Group' });
        if (colDefGroup && colDefGroup.field && !params.data[GroupHeaderKey] && colDefGroup.field !== fieldValue) {
          fieldValue = params.data[colDefGroup.field];
        }
        const getChildrenCount = (child: BasicPivotItem) => child.children.reduce((i, c) => c.children.length + i, 0);
        const getCount = (node1: BasicPivotItem): number => {
          const childrenCount = getChildrenCount(node1);
          if (childrenCount > 0) {
            const childrenChildrenCount = node1.children.reduce((i, n) => getCount(n) + i, 0);
            if (childrenChildrenCount > 0) {
              return childrenChildrenCount;
            }
            return childrenCount;
          }
          return node1.children.length;
        };

        const count = params.data ? getCount(params.data) : 0;
        return params.data[GroupHeaderKey] && count > 0 ? `${fieldValue} (${count})` : fieldValue;
      };
      treeColumnDefinition.valueFormatter = undefined;
    }
    const gridOptions: DataGridProps = {
      isPrintMode,
      defaultShownValues,
      columnDefs: colDefs,
      data: gridData,
      frameworkComponents,
      autoSizeOnReady: true,
      rowHeight,
      loaded: gridDataLoaded,
      rowClassRules: {
        'header-row': (params: CellClassParams) => !isNil(params.data) && !isNil(params.data[GroupHeaderKey]),
      },
      exportOptions: {
        fileName: this.props.title,
      },
      treeColumnDefinition: treeCol,
      onGridReady: (event: GridReadyEvent) => {
        this.gridApi = event.api;
        this.columnApi = event.columnApi;
      },
    };

    let configureModalProps: ConfigureModalProps;

    if (showConfigure && configureOptionGroups) {
      subheaderProps.configureOptions = {
        type: 'enabled',
        onConfigureClick: () => {
          this.setState({
            configureIsOpen: true,
          });
        },
      };

      configureModalProps = {
        enabled: true,
        isOpen: configureIsOpen,
        optionGroups: configureOptionGroups,
        selections: configureSelections,
        instructions: configureInstructions,
        minimumSelections: minimumSelections,
        onReset: () => {
          this.updateConfigureSelections(defaultConfigureSelections);
          this.setState({
            configureLastSelected: defaultConfigureSelections,
          });
        },
        onToggleModal: (action) => {
          const nextState: Partial<State> = {
            configureIsOpen: !configureIsOpen,
          };

          switch (action) {
            case 'apply': {
              if (onConfigAggregate) {
                const aggBys = configureSelections.map((sel) => sel.dataIndex);
                onConfigAggregate(aggBys);
              }
              nextState.configureLastSelected = configureSelections;
              break;
            }
            default:
              this.updateConfigureSelections(configureLastSelected);
          }

          this.setState(nextState as any);
        },
        selectionUpdate: this.updateConfigureSelections,
      };
    } else {
      configureModalProps = { enabled: false };
    }

    const gridContainerClasses = classes('grid-container', isMacroExpanded ? 'expanded' : 'collapsed');
    return (
      <div className={containerClass} data-qa="macro-grid-pair-container">
        <Subheader {...subheaderProps} />
        <div className="macro-container">
          <React.Fragment>
            <div className={dataContainerStyle}>
              {!isEmpty(macros) && (
                <MacroSummariesAccordion
                  dataLoaded={macroDataLoaded}
                  macros={macros}
                  onToggleAccordion={this.toggleSummary}
                />
              )}
              <StyledAccordion expanded={false} expandable={false} title="Details"></StyledAccordion>
              <div className={gridContainerClasses} style={this.props.fullHeight ? { height: '100%' } : undefined}>
                <ExtendedDataGrid {...gridOptions} />
              </div>
            </div>
            <ConfigureModal {...configureModalProps} />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
