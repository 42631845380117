import { ViewDataState } from 'src/types/Domain';

export enum PlanItemStatus {
  Pending = 'pending',
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
}

export enum AddToAssortmentStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export interface PlanItem {
  id: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: PlanItemStatus;
  hierarchy: string[];
}

export interface AddToAssortmentItem {
  cartId: string;
  masterCount: number;
  createdAt: string;
  updatedAt: string;
  status: AddToAssortmentStatus;
}

export interface UndoItem {
  undoId: string;
  createdAt: string;
  planMeasure: string[];
  initiator: string;
  initiatorName?: string;
  initiatorEmail?: string;
  modificationLevelTie: string[];
  modifiedRecords: number;
  examplarMemberTie?: string[];
}

export interface MinimalItem {
  primaryText: string;
  updatedAt: string;
  /** allow additional props in type in case you need more data for the secondary renderer */
  [otherKeys: string]: unknown;
}

export interface PlanTrackerSlice {
  assortmentPlanInProgress: boolean;
  shouldCheckAssortment: boolean;
  askForRefresh: boolean;
  refreshRequestedAt?: number;
  planItems: PlanItem[];
  undoViewState: ViewDataState;
  undoItems: UndoItem[];
  addToAsstInProg: boolean;
  addToAsstItems: AddToAssortmentItem[];
}

export enum AddToAssortmentTrackerStatus {
  Queued = 'QUEUED',
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Complete = 'COMPLETED',
  Pending = 'PENDING',
}

export interface AddToAssortmentTracker {
  processing: boolean;
  userCarts: Record<AddToAssortmentTrackerStatus, AddToAssortmentItem[]>;
}
