import { style, media } from 'typestyle';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

const mainView = style(
  media(
    { minWidth: 0, maxWidth: 900 },
    {
      marginTop: 10,
    }
  ),
  media(
    { minWidth: 900 },
    {
      marginTop: 0,
    }
  ),
  media({ minWidth: 0, maxWidth: 1500 }, { height: `calc(100% - ${SUBHEADER_HEIGHT}px)` }),
  {
    $debugName: 'mainView',
    height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
  }
);

const inPrintMode = style({
  $debugName: 'inPrintMode',
  // TODO: This is not actually a solution, fitview should be modified for print styling.
  // It...'works' for now.
  height: `calc(100vh - ${SUBHEADER_HEIGHT}px)`,
});

const styles = {
  fab: style({
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    color: '#fff',
    backgroundColor: '#1fa67a',
    width: 56,
    height: 56,
    padding: 0,
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    borderRadius: '50%',
    zIndex: 999,
    cursor: 'pointer',
  }),
};

export default {
  mainView,
  inPrintMode,
  styles,
};
