import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import { fetchCompanionListData, updateSelectedLevel, receiveWorklistConfig, cleanUp } from './Worklist.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import {
  receiveScope,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import {
  ConfDefnComponentType,
  WorklistComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isNil } from 'lodash';
import { preSetActivePage } from '../NavigationShell/NavigationShell.slice';

// Separate config loading from level selection. Config load triggers
// the selected level (which the the relevant part for data fetching).
export const worklistConfigLoaded: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(receiveWorklistConfig.type),
    map(() => maybeGetComponentProps<WorklistComponent>(state$.value, ConfDefnComponentType.worklist)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(() => {
      const { viewDefn } = state$.value.worklist;
      if (viewDefn == null) return of();
      let levelField;
      const levelBy = viewDefn.companionView.levelBy;
      if (levelBy && levelBy.view) {
        const defaultLevelBy = levelBy.defaults.dataIndex;
        let defaultLevelSelection = levelBy.view.find((i) => i.dataIndex === defaultLevelBy);
        if (defaultLevelSelection == null) {
          defaultLevelSelection = levelBy.view[0];
        }
        levelField = defaultLevelSelection;
        return of(updateSelectedLevel(levelField));
      } else {
        return of(updateSelectedLevel(undefined));
      }
    })
  );
};

export const worklistCompanionLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveFilterStateAfterSubmission.type,
      receiveScopeRefreshTrigger.type,
      updateSelectedLevel.type,
      receiveWorklistConfig.type,
      'LENS_ASST_PLAN_REFRESHING_PAGE',
      receiveScope.type
    ),
    map(() => maybeGetComponentProps<WorklistComponent>(state$.value, ConfDefnComponentType.worklist)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap(({ defns }) => {
      const { selectedLevel, viewDefn } = state$.value.worklist;

      if (isNil(viewDefn)) {
        return of();
      }
      const levelBy = viewDefn.companionView.levelBy;
      if (!isNil(levelBy) && isNil(selectedLevel)) {
        return of();
      } else {
        return of(fetchCompanionListData(defns.model, { aggBy: selectedLevel?.groupingKey }));
      }
    })
  );
};

export const worklistCompanionReset: AppEpic = (action$) => {
  return action$.pipe(
    ofType(preSetActivePage.type),
    mergeMap(() => {
      return of(cleanUp());
    })
  );
};
