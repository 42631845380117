import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import { get, isEmpty } from 'lodash';
import {
  ConfDefnComponentType,
  EditableOverTimeGridComponent,
  isWorklistActive,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';

import {
  fetchOverTimeData,
  updateSelectedItem,
  refreshOvertimeData,
  fetchCompanionData,
} from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';

export const pricingOverTimeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateSelectedItem.type, refreshOvertimeData.type),
    filter((action) => {
      if (action.type === updateSelectedItem.type && isEmpty(action.payload)) {
        return false;
      }
      return true;
    }),
    map(() => maybeGetComponentProps<EditableOverTimeGridComponent>(state$.value, ConfDefnComponentType.pricing)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap((componentProps) => {
      const { selectedItem } = state$.value.pages.assortmentBuild.pricing;
      // we allow a third element to be passed in from `componentProps.defns.models` in flowsheet/pricing,
      // to override the modeldefn, in order to support different pivot runs
      const maybeThirdModel = get(componentProps.defns.models, '2', undefined);

      if (!isEmpty(selectedItem)) {
        return of(fetchOverTimeData(selectedItem, maybeThirdModel));
      }

      return of();
    })
  );
};

export const pricingOvertimeCompanionLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(receiveScopeRefreshTrigger.type, 'LENS_ASST_PLAN_REFRESHING_PAGE', receiveFilterStateAfterSubmission.type),
    map(() => maybeGetComponentProps<EditableOverTimeGridComponent>(state$.value, ConfDefnComponentType.pricing)),
    filter(inputIsNotNullOrUndefined),
    filter(() => !isWorklistActive(state$.value)),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap(({ defns }) => {
      return of(updateSelectedItem(''), fetchCompanionData(defns.models[0]));
    })
  );
};
