///Pages/resetPages

import { ofType } from 'redux-observable';
import { mergeMap, map, of, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { clearCartItems, clearCartLocalState, initCart } from './AssortmentCart.slice';
import { setActivePage } from '../NavigationShell/NavigationShell.slice';
import {
  AssortmentCartViewComponent,
  ConfDefnComponentType,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import { scopeLens } from 'src/services/lenses/lenses';
import {
  receiveScope,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';

export const clearAssortmentCart: AppEpic = (action$, _state$) => {
  return action$.pipe(
    ofType('Pages/resetPages'),
    mergeMap(() => {
      return of(clearCartLocalState());
    })
  );
};
export const resetCartEpic: AppEpic = (action$) => {
  return action$.pipe(
    ofType(receiveScope.type, receiveScopeRefreshTrigger.type),
    mergeMap(() => {
      // whenever scope changes, dump the cart
      // this is separate from initAssortmentCart as we only want items wiped on scope change, *not* whenever nav happens
      return of(clearCartItems());
    })
  );
};
export const initAssortmentCart: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type, receiveScope.type, receiveScopeRefreshTrigger.type),
    map(() => {
      return maybeGetComponentProps<AssortmentCartViewComponent>(state$.value, ConfDefnComponentType.assortmentCart);
    }),
    filter(() => isScopeDefined(state$.value.scope)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      const { view } = defns;
      const department = scopeLens.get(state$.value).productMember ?? '_MISSING_SCOPEPRODUCT_';
      return of(initCart(view[0], department));
    })
  );
};
