import { partial, noop, isNil, cloneDeep, find } from 'lodash/fp';
import { flow } from 'lodash';
import { Lens } from 'monocle-ts';
import { connect } from 'react-redux';
import { update } from 'src/services/lenses/Lenses.actions';
import { assortmentCartItemsLens, scopeLens, assortmentCartLens } from 'src/services/lenses/lenses';
import {
  getAllColors,
  getNewStyleColor,
  getAllPowerdrivers,
  generateCart,
} from 'src/pages/AssortmentBuild/AssortmentAdd/Assortment.client';
import { LifecycleStoreData } from 'src/components/LifecycleStoreModal/LifecycleStoreModal';
import { AssortmentCart, AssortmentCartFunctionProps, Powerdriver } from './AssortmentCart';
import { mapCartItems } from './AssortmentCart.selectors';
import { makeCartService } from './AssortmentCart.service';
import { CartItem, CartItemType, ColorSwatchItem } from './AssortmentCart.types';
import { AssortmentClient } from '../AssortmentBuild/Assortment.client';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import {
  validateStyleName,
  getStyleAttributes,
  validateAllStyleNames,
} from '../AssortmentBuild/StyleEdit/StyleEdit.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { resolvePath } from 'src/cdn';

import { watermarkImageAsDataURI } from 'src/utils/Images/WatermarkImage';
import { IMG_URI } from 'src/utils/Domain/Constants';
import { createId } from '@paralleldrive/cuid2';
import { AppState, AppThunkDispatch } from 'src/store';
import { z } from 'zod';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { AssortmentCartSlice, setCartInfo } from './AssortmentCart.slice';
import { AssortmentCartViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { WithRouterProps, withRouter } from 'src/components/higherOrder/withRouter';

const noImage = resolvePath(noImagePath);

export interface AssortmentCartOwnProps extends z.infer<typeof AssortmentCartViewComponentProps>, WithRouterProps {}

function parsePowerdriversToSubheader(data: BasicPivotItem[]): Powerdriver[] {
  return data
    .map((powerdriver) => {
      return {
        id: powerdriver.id,
        dataIndex: powerdriver.id,
        text: powerdriver.name,
        style: powerdriver['attribute:conceptstyleid:name'],
        styleColor: powerdriver['attribute:conceptstylecolorid:name'],
      };
    })
    .filter((transformedPD) => !isNil(transformedPD.style));
}

function mapStateToProps(state: AppState, ownProps: AssortmentCartOwnProps) {
  const { title } = ownProps;
  const scope = scopeLens.get(state);

  const cartItemsToAddCount = assortmentCartLens
    .compose(Lens.fromProp<AssortmentCartSlice>()('cartItemsToAddCount'))
    .get(state);
  const { cartInfo, viewDefn, powerdrivers, colors } = assortmentCartLens.get(state);
  const cartItems = mapCartItems(assortmentCartItemsLens.get(state) as unknown as BasicPivotItem[]);
  return {
    title,
    items: cartItems,
    cartItemsToAddCount: cartItemsToAddCount,
    colors: colors || [],
    department: scope.productMember || '',
    powerdrivers: powerdrivers || [],
    defns: ownProps.defns,
    canAddNewStyles: state.appConfig.tenantConfig.canAddNewStyles,
    defnId: ownProps.defns.view[0],
    cartInfo: cartInfo,
    viewDefn: viewDefn,
  };
}
export interface AssortmentCartValueProps extends ReturnType<typeof mapStateToProps> {}

function mapDispatchToProps(dispatch: AppThunkDispatch): AssortmentCartFunctionProps {
  const service = makeCartService(assortmentCartItemsLens, '_id');
  const factory = {
    onShowView: async () => {},
    onRefetchData: () => {},
    removeAllItems: () => {
      dispatch(update(service.removeAllItemsFromCart, 'Destroying Cart.'));
      window.history.back();
    },
    addItemFromStyle: (styleId: string, activePowerdriverIndex: number) => {
      dispatch(async (_dispatch: AppThunkDispatch, getState: () => AppState) => {
        const powerdriver = assortmentCartLens.get(getState()).powerdrivers.find((i) => {
          return i.style === styleId;
        });
        if (!isNil(powerdriver)) {
          const styleColorId = powerdriver.styleColor;
          const style = await getStyleAttributes(styleId);
          style._id = createId();
          const styleColor = await getStyleAttributes(styleColorId);
          styleColor['attribute:isassortment:id'] = 'false';
          if (isNil(styleColor['attribute:img:id'])) {
            styleColor['attribute:img:id'] = await watermarkImageAsDataURI(noImage);
          }
          style.children = [styleColor];
          if (isNil(style['attribute:img:id'])) {
            style['attribute:img:id'] = styleColor['attribute:img:id'];
          }
          style.type = 'concept';
          style.activePowerdriverIndex = activePowerdriverIndex;
          const name = `CONCEPT_${style.name}`;
          style.name = name;
          style.valid = await validateStyleName(name);

          // @ts-ignore
          dispatch(update(partial(service.addItemToCart, [style]), `Duplicating item ${style.name} in cart.`));
        }
      });
    },
    changeItemPowerdriver: (item: CartItem, id: string, index: number) => {
      dispatch(update(partial(service.updateItemKeyFromCart, ['id', id, item]), 'Updating Id.'));
      dispatch(
        update(
          partial(service.updateItemKeyFromCart, ['activePowerdriverIndex', index, item]),
          'Updating PowerdriverIndex.'
        )
      );
    },
    duplicateItem: (item: CartItem) => {
      dispatch(async (_dispatch: AppThunkDispatch, getState: () => AppState) => {
        const oldItem = service.fetchItem(item, getState());
        if (isNil(oldItem)) {
          return;
        }
        const newItem = cloneDeep(oldItem);
        newItem._id = createId();
        if (newItem.type === CartItemType.existing && newItem.children) {
          const newimageURI = newItem[IMG_URI];
          newItem[IMG_URI] =
            (await watermarkImageAsDataURI(newimageURI).catch(() => watermarkImageAsDataURI(noImage))) ||
            newItem[IMG_URI];
          newItem.type = CartItemType.similar;
          newItem.name = `S5_${oldItem.name}`;
          await newItem.children.reduce(async (prom, child) => {
            child[IMG_URI] =
              (await watermarkImageAsDataURI(newimageURI).catch(() => watermarkImageAsDataURI(noImage))) ||
              child[IMG_URI];
            child.type = 'similar';
            child.name = `S5_${child.name}`;
            return prom;
          }, {});
        }
        dispatch(update(partial(service.addItemToCart, [newItem]), `Duplicating item ${item.name} in cart.`));
      });
    },
    removeItem: (item: CartItem) => {
      dispatch(update(partial(service.removeItemFromCart, [item]), `Removing item ${item.id} from cart.`));
    },
    changeItemDescription: (item: CartItem, description: string) => {
      dispatch(
        update(partial(service.updateItemKeyFromCart, ['description', description, item]), 'Updating Description.')
      );
      const newSwatches = item.swatches.map((sw) => {
        sw.description = `${description}: ${sw['attribute:cccolor:id']}`;
        return sw;
      });
      factory.changeItemSwatches(item, newSwatches);
    },
    changeItemName: (item: CartItem, name: string) => {
      if (item.name === name && item.type === CartItemType.existing) {
        noop();
      }
      dispatch(async (_dispatch: AppThunkDispatch, getState: () => AppState) => {
        validateStyleName(name).then((isValid: boolean) => {
          if (isValid) {
            isValid =
              assortmentCartLens
                .get(getState())
                .cartItems.findIndex((otherItem) => otherItem.name === name && otherItem.id !== item.id) < 0;
          }
          return dispatch(
            update(partial(service.updateItemKeyFromCart, ['valid', isValid, item]), 'Marking Name Validity.')
          );
        });
      });

      if (item.name !== name) {
        dispatch(update(partial(service.updateItemKeyFromCart, ['name', name, item]), 'Updating Name.'));
        const newSwatches = item.swatches.map((sw) => {
          sw.name = `${name}-${sw['attribute:cccolorid:id']}`;
          return sw;
        });
        factory.changeItemSwatches(item, newSwatches);
      }
    },
    saveItems: async (lifecycleStoreData?: LifecycleStoreData) => {
      const prom = new Promise<void>(function(resolve, reject: (reason: string) => void) {
        dispatch(async (_ign: AppThunkDispatch, getState: () => AppState) => {
          const state = getState();
          const items = assortmentCartLens.get(state).cartItems;
          const cart_id = assortmentCartLens.get(state).cartInfo?.cart_id;
          if (cart_id == null) {
            // TODO: make this error, as cart failed to generate
            return;
          }
          try {
            const validation = await validateAllStyleNames((items as unknown) as BasicPivotItem[]);
            if (find((item) => item.valid === false, validation)) {
              reject('InvalidItems');
              return;
            }
            // TODO: fix type here or project to correct type
            // @ts-ignore
            await AssortmentClient.addItemsToAssortment({ items, lifecycleStoreData, cart_id });
            dispatch(update(service.removeAllItemsFromCart));
            resolve();
          } catch (err) {
            reject('AddFailed');
          }
        });
      });
      await prom;
    },
    changeItemSwatches: (item: CartItem, swatches: ColorSwatchItem[]) => {
      dispatch(
        update(partial(service.updateItemKeyFromCart, ['children', swatches, item]), `Updated colors of ${item.name}`)
      );
    },
    getStyleColor: async (styleId: string) => {
      return await getNewStyleColor(styleId).then((styleColor) => {
        return styleColor;
      });
    },
    getStyleColorPlaceholder: async () => {
      throw 'This should be doing placeholder logic.';
    },
  };
  return factory;
}

const sensitiveView = flow(() => AssortmentCart, withRouter, makeScopeSensitive)();
export default connect(mapStateToProps, mapDispatchToProps)(sensitiveView);