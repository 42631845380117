import React from 'react';
import { classes } from 'typestyle';
import { get } from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { keyFinancialStyle } from 'src/common-ui/components/Macros/KeyFinancial/KeyFinancial.styles';
import { getDirectionClasses } from 'src/common-ui/components/Macros/Macros.utils';

export default function KeyFinancial(props: MacrosProps) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { dataLoaded, metrics, extraClasses, ignoreHorizontalSpacing = false } = props;
  const { primary, subMetrics } = metrics;
  // Create defaults incase the other options aren't configured, instead of silently erroring
  const containerClass = classes('key-financial-item', keyFinancialStyle, extraClasses ? extraClasses : '');
  const rightContainerClass = classes('right-container', ignoreHorizontalSpacing ? 'right-container-override' : '');
  const header = get(primary, 'label', '');

  if (!dataLoaded) {
    return (
      <div className={containerClass}>
        <header>{header}</header>
        <div className="bottom-container">
          <Skeleton className="primary-skeleton" variant="text" animation="wave" />
          <div className={rightContainerClass}>
            <section className="secondary-skeleton">
              <Skeleton className="arrow-skeleton dir" variant="text" animation="wave" />
              <Skeleton className="diff-skeleton" variant="text" animation="wave" />
            </section>
            <Skeleton variant="text" animation="wave" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={containerClass}>
      <header>{header}</header>
      <div className="bottom-container">
        <div className="primary">{primary.rendered}</div>
        <div className={rightContainerClass}>
          {subMetrics.map((metric) => {
            return (
              <React.Fragment key={metric.label}>
                {metric.direction ? (
                  <div className="diff-container">
                    <i className={getDirectionClasses(metric.direction).iconClass} />
                    <span className="diff">{metric.rendered}</span>
                  </div>
                ) : (
                  <div className="secondary">
                    {metric.label} {metric.rendered}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
