import React, { Component } from 'react';
import { debounce, isNil, isEqual, noop, first, isEmpty } from 'lodash';
import { classes } from 'typestyle';
import { CompanionListView } from 'src/common-ui/index';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { resolvePath } from 'src/cdn';
import { CompanionListProps, CompanionListState } from 'src/components/CompanionList/CompanionList.types';
import { companionStyles } from '../../common-ui/components/CompanionListView/CompanionListView.styles';
import SubheaderCheckboxes from 'src/components/Subheader/SubheaderCheckboxes';
import { FlowStatus } from 'src/services/configuration/codecs/bindings.types';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

const noImage = resolvePath(noImagePath);

type CompanionListSearchProps = {
  search: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CompanionListSearch = ({ search, onChange }: CompanionListSearchProps) => {
  return (
    <input
      type="text"
      className="form-control"
      placeholder="Search list..."
      aria-label="Search list"
      onChange={onChange}
      defaultValue={search}
      data-qa="CompanionListSearchInput"
    />
  );
};

type CompanionListFilterProps = {
  flowStatus: number[];
  flowStatusOptions: FlowStatus;
  onFilterChange: (values: number[]) => void;
};

const CompanionFilter = ({ flowStatus, flowStatusOptions, onFilterChange }: CompanionListFilterProps) => {
  return (
    <SubheaderCheckboxes
      flowStatus={flowStatus}
      flowStatusOptions={flowStatusOptions}
      handleValuesUpdate={onFilterChange}
      qaPrefix="companion-list-view"
      show={true}
    />
  );
};

export default class CompanionList extends Component<CompanionListProps, CompanionListState> {
  debounceUpdateSearch: (value: string) => void;

  constructor(props: CompanionListProps) {
    super(props);

    this.debounceUpdateSearch = props.onSearchChange ? debounce(props.onSearchChange, 200) : noop;

    this.state = {
      collapsed: false,
    };
  }

  componentDidUpdate(prevProps: CompanionListProps) {
    if (!isEqual(this.props.data, prevProps.data)) {
      // If the data is not yet fully loaded (e.g. during a reload), do nothing
      // This avoids a temporary empty data state resetting the selected item

      if (!this.props.isDataloaded) {
        return;
      }
      // Check if parent's selected item still exists in new data
      const alreadyValidSelection = this.props.data.some((item) => item.id === this.props.selectedItemId);

      // If not (or if there was no selectedItemId), THEN fallback to first item if items exist
      // (this was previously updating/overwriting the parent all the time)
      if (!alreadyValidSelection) {
        const firstSelection = first(this.props.data);
        if (firstSelection) {
          this.props.onItemSelect(firstSelection.id);
        } else {
          // Otherwise - no selection
          this.props.onItemSelect('');
        }
      }
    }
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.debounceUpdateSearch(event.currentTarget.value);
  };

  handleFilterChange = (values: number[]) => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(values);
    }
  };

  handleSortDirectionChange = (sortDirection: SortByDirection) => {
    if (this.props.onSortDirectionChange) {
      this.props.onSortDirectionChange(sortDirection);
    }
  };

  handleSortSelection = (selection: SortOption) => {
    if (this.props.onSortChange) {
      this.props.onSortChange(selection.dataIndex);
    }
  };

  handleToggleCollapse = (isCollapsed: boolean) => this.setState({ collapsed: isCollapsed });

  handleLevelSelection = (levelOption: SortOption) => {
    const { onLevelChange } = this.props;
    if (onLevelChange) {
      onLevelChange(levelOption.dataIndex);
    }
  };

  handleListItemClicked = (identityValue: string) => {
    const { data, onlyUserInitiatedClicks, onItemSelect } = this.props;
    const selectedItemIndex = data.findIndex((item) => item.id === identityValue);
    const selectedItem = data[selectedItemIndex];

    if (!isNil(selectedItem)) {
      onItemSelect(selectedItem.id);
    } else if (!onlyUserInitiatedClicks && isNil(selectedItem)) {
      const clickedItem = data.find((dataItem) => dataItem.id === identityValue);
      if (!isNil(clickedItem)) {
        onItemSelect(clickedItem.id);
      }
    }
  };

  renderSearchComponent = () => {
    const { renderSearchComponent, search = '' } = this.props;

    return renderSearchComponent ? (
      <CompanionListSearch search={search} onChange={this.handleSearchChange} />
    ) : undefined;
  };

  renderFilterComponent = () => {
    const { renderFilterComponent, flowStatus = [], flowStatusOptions = {} as FlowStatus } = this.props;

    return renderFilterComponent ? (
      <CompanionFilter
        flowStatus={flowStatus}
        flowStatusOptions={flowStatusOptions}
        onFilterChange={this.handleFilterChange}
      />
    ) : undefined;
  };

  render() {
    const { config, data, dataLookup, isDataloaded, levelField, scrollTo, selectedItemId, sortDirection, sortField } =
      this.props;

    const levelIndex = config.levelBy?.view.findIndex(({ dataIndex }) => dataIndex === levelField);
    const sortIndex = config.sortBy.options.findIndex(({ dataIndex }) => dataIndex === sortField);
    const selectedIndex = data.findIndex((item) => item.id === selectedItemId);

    return (
      <CompanionListView
        label={'Count'}
        className={classes(companionStyles)}
        sortOptions={config.sortBy.options as SortOption[]}
        defaultSelection={sortIndex}
        levelOptions={(config.levelBy?.view as SortOption[]) ?? undefined}
        defaultLevelSelection={levelIndex}
        selectedIndex={selectedIndex}
        data={data}
        isDataloaded={isDataloaded}
        noImageUrl={noImage}
        scrollTo={scrollTo}
        dataLookup={dataLookup}
        searchComponent={this.renderSearchComponent()}
        filterComponent={this.renderFilterComponent()}
        initialSortDirection={sortDirection}
        hoverListItemElement={this.props.hoverActionElement}
        onListItemClicked={this.handleListItemClicked}
        onChangeDirection={this.handleSortDirectionChange}
        onSortSelection={this.handleSortSelection}
        onLevelSelection={this.handleLevelSelection}
        onToggleCollapse={this.handleToggleCollapse}
      />
    );
  }
}
