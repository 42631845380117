import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { TEAL_PRIMARY, smallMediumFont } from 'src/utils/Style/Theme';
import { style } from 'typestyle';

const container = style({
  padding: '0 1.5rem',
});

const titleText = style({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const itemsContainer = style({
  height: '100%',
});

const item = style({
  marginBottom: '2rem',
});

const itemTitle = style({
  display: 'flex',
  alignItems: 'center',
});

const searchContainer = style({
  width: '100%',
  padding: '1rem 0',
  $nest: {
    input: {
      width: '100%',
      border: '1px solid #ced4da',
      fontSize: smallMediumFont,
      padding: '0.3rem 0.75rem',
      height: 'calc(1.5em + 0.75rem + 2px)',
      $nest: {
        '&:focus-visible': {
          color: '#495057',
          backgroundColor: '#fff',
          borderColor: '#29ffee',
          outline: 0,
          boxShadow: '0 0 0 0.2rem rgba(0, 168, 155, 0.25)',
        },
      },
    },
  },
});

export const usePlanQueueStyles = (labelColor?: string, totalSectionHeight?: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      small: {
        width: theme.spacing(3),
        height: theme.spacing(2.5),
        '& > span': {
          fontSize: '0.75rem',
        },
        marginRight: '0.5rem',
        backgroundColor: labelColor ?? TEAL_PRIMARY,
      },
      medium: {
        fontWeight: 'bold',
      },
      large: {
        fontWeight: 300,
        fontSize: '1.5rem',
      },
      accordionSummary: {
        width: '100%',
      },
      accordionContainer: {
        boxShadow: 'none',
      },
      accordionDetailsList: {
        width: '325px',
        height: totalSectionHeight && totalSectionHeight,
        maxHeight: 500,
        minHeight: 30, // for when there are no items in the list
      },
      divider: {
        height: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    })
  );

export default {
  container,
  titleText,
  itemsContainer,
  item,
  itemTitle,
  searchContainer,
};
