import { style, media } from 'typestyle';
import { TEAL_PRIMARY, smallFont, smallMediumFont, largeFont, extraLargeFont } from 'src/utils/Style/Theme';
import { important, rem } from 'csx';

export const SUBHEADER_HEIGHT = 100;
export const SUBHEADER_WIDTH_BREAKPOINT = 1650;

export const getFiltersStyle = (visible: boolean) => {
  return style({
    display: important(visible ? 'flex' : 'none'),
  });
};

export const containerStyle = style(
  media(
    { minWidth: 0 },
    {
      padding: '0 0.15rem',
      $nest: {
        '.configure': {
          alignItems: 'center',
          backgroundColor: 'transparent',
          border: '0 none',
          display: 'flex',
          marginRight: rem(0.5),
          paddingTop: rem(0.4),
          $nest: {
            '&:hover': {
              cursor: 'pointer',
            },
            '> span': {
              fontSize: smallFont,
              marginLeft: '0.3rem',
            },
          },
        },
        '.direction-select': {
          color: TEAL_PRIMARY,
          cursor: 'pointer',
          marginLeft: '1rem',
          transitionDuration: '0.2s',
        },
        '.dropdown-group': {
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          fontSize: smallMediumFont,
          maxWidth: '14rem',
          margin: '0 1rem',
          $nest: {
            '.dropdown-item': {
              height: '2rem',
            },
            '& + .dropdown-group': {
              margin: 0,
            },
            '.selected-text': {
              color: TEAL_PRIMARY,
              whiteSpace: 'nowrap',
            },
            '.dropdown': {
              backgroundColor: 'white',
              cursor: 'pointer',
              display: 'flex',
              flex: 1,
              marginLeft: '0.5rem',
              width: 'calc(100% - 80px)',
              $nest: {
                '.dropdown-toggle': {
                  height: 24,
                  overflow: 'hidden',
                  width: '100%',
                },
                i: {
                  color: TEAL_PRIMARY,
                },
                '> div:first-child': {
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                  display: 'flex',
                  flex: 1,
                  height: '100%',
                  justifyContent: 'space-between',
                },
              },
            },
            '> label': {
              margin: 0,
            },
          },
        },
        '.dropdown-menu': {
          fontSize: smallFont,
          maxHeight: '400px',
          overflow: 'auto',
          width: '100%',
          $nest: {
            '.dropdown-item': {
              cursor: 'pointer',
            },
          },
        },
        '.look-back': {
          $nest: {
            'input[type="radio"]': {
              fontSize: '0.5rem',
            },
            'input[type="radio"]:checked+label': {
              color: TEAL_PRIMARY,
            },
            '.radio-group > label': {
              fontSize: '0.5rem',
              padding: '0 10px 0 5px',
              textTransform: 'uppercase',
            },
          },
        },
        '.search': {
          alignSelf: 'center',
          maxWidth: '20rem',
          width: '100%',
          padding: 0,
          $nest: {
            '.form-control': {
              padding: 0,
              fontSize: smallMediumFont,
              borderRadius: 0,
            },
            '.Mui-focused': {
              color: '#495057',
              backgroundColor: '#fff',
              borderColor: '#29ffee',
              outline: 0,
              boxShadow: '0 0 0 0.2rem rgba(0, 168, 155, 0.25)',
            },
            '.s5-MuiOutlinedInput-notchedOutline': {
              borderWidth: 0,
            },
            '.s5-MuiOutlinedInput-root.Mui-focused .s5-MuiOutlinedInput-notchedOutline': {
              borderWidth: 0,
            },
          },
        },
        '.spacer': {
          // TO DO: This will be unnecessary when HTML is valid
          width: '2rem',
        },
        '.title-container': {
          gridArea: 'title-container',
          display: 'flex',
          alignItems: 'center',
        },
        '.hidden-title-container': {
          gridArea: 'title-container',
          display: 'flex',
          alignItems: 'center',
        },
        '.title': {
          fontSize: largeFont,
          fontWeight: 300,
          margin: 0,
          marginRight: rem(0.5),
          whiteSpace: 'nowrap',
        },
        '.quick-actions': {
          gridArea: 'quick-actions',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          $nest: {
            '.filters-toggle': {
              alignSelf: 'center',
              paddingBottom: rem(0.4),
            },
          },
        },
        '.filters': {
          gridArea: 'filters',
          // display: 'flex', // proper display value handled in method above
          justifyContent: 'space-between',
          margin: '0.25rem 0',
          $nest: {
            '.flow-search-filters': {
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              $nest: {
                '.flow-status-checkboxes': {
                  marginRight: rem(0.25),
                },
              },
            },
            '.dropdown-filters': {
              display: 'flex',
              alignItems: 'center',
              $nest: {
                '.asst-add-range-picker': {
                  marginRight: rem(1),
                },
                '.form-control-dropdown': {
                  marginRight: rem(1),
                  marginTop: 0,
                  marginBottom: 0,
                },
                '.direction-select': {
                  margin: 0,
                },
              },
            },
          },
        },
        '.top-bar': {
          display: 'grid',
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto',
          gridTemplateAreas: `
            "title-container quick-actions"
            "filters filters"
          `,
          paddingTop: '0.5rem',
          minHeight: 40,
        },
        '.breadcrumb-link': {
          display: 'flex',
          alignItems: 'center',
          marginRight: '1rem',
          color: TEAL_PRIMARY,
          $nest: {
            '&:hover': {
              cursor: 'pointer',
            },
            '& .fa': {
              paddingRight: '0.5rem',
            },
          },
        },
      },
    }
  ),
  media(
    { minWidth: 900 },
    {
      $nest: {
        '.title': {
          fontSize: extraLargeFont,
        },
        '.search': {
          $nest: {
            '.form-control': {
              width: '100%',
            },
          },
        },
      },
    }
  ),
  media(
    { minWidth: SUBHEADER_WIDTH_BREAKPOINT },
    {
      $nest: {},
    }
  )
);
