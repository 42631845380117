import moment from 'moment';
import { red, grey } from '@material-ui/core/colors';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import {
  AddToAssortmentItem,
  AddToAssortmentStatus,
  MinimalItem,
  PlanItem,
  PlanItemStatus,
} from 'src/pages/AssortmentBuild/Planning.types';
import { reverse, sortBy } from 'lodash';

export function mapPlanItem(item: PlanItem): MinimalItem {
  return {
    primaryText: item.description ?? item.id,
    updatedAt: item.updatedAt,
  };
}

const ASST_TIME_FORMAT = 'h:mma - MM/DD/YYYY';
export function formatAsstTime(time: string) {
  return moment(time).format(ASST_TIME_FORMAT);
}

export const getIconBadgeColor = (items: {
  addProcessing?: unknown[];
  addFailed?: unknown[];
  planPending?: unknown[];
  planProcessing?: unknown[];
  planCompleted?: unknown[];
  planFailed?: unknown[];
  /** Returns color as a hex value instead of the material-ui `color` value  */
  cssColorFormat?: boolean;
}) => {
  const {
    addProcessing = [],
    addFailed = [],
    planPending = [],
    planProcessing = [],
    planCompleted = [],
    planFailed = [],
    cssColorFormat = false,
  } = items;

  if (
    addProcessing.length === 0 &&
    addFailed.length === 0 &&
    planPending.length === 0 &&
    planProcessing.length === 0 &&
    planCompleted.length === 0 &&
    planFailed.length === 0
  ) {
    return cssColorFormat ? grey[500] : 'default';
  }

  if (planProcessing.length > 0 || addProcessing.length > 0) {
    return cssColorFormat ? TEAL_PRIMARY : 'secondary';
  } else if (planFailed.length > 0 || addFailed.length > 0) {
    return cssColorFormat ? 'red' : red[500];
  }

  // teal for planPending/planCompleted
  return cssColorFormat ? TEAL_PRIMARY : 'secondary';
};

const getPlanItemsByStatus = (items: PlanItem[], filterStatus: PlanItemStatus) => {
  return items.filter(({ status }) => status === filterStatus);
};

export const parsePlanItems = (items: PlanItem[]) => {
  return {
    pending: getPlanItemsByStatus(items, PlanItemStatus.Pending),
    processing: getPlanItemsByStatus(items, PlanItemStatus.Processing),
    completed: getPlanItemsByStatus(items, PlanItemStatus.Completed),
    failed: getPlanItemsByStatus(items, PlanItemStatus.Failed),
  };
};

const getAddItemsByStatus = (items: AddToAssortmentItem[], filterStatus: AddToAssortmentStatus) => {
  return items.filter(({ status }) => status === filterStatus);
};

export const parseAddItems = (items: AddToAssortmentItem[]) => {
  return {
    processing: getAddItemsByStatus(items, AddToAssortmentStatus.Processing),
    failed: getAddItemsByStatus(items, AddToAssortmentStatus.Failed),
  };
};

export function parsePlanQueueItems(planItems: PlanItem[], addToAsstItems: AddToAssortmentItem[]) {
  const {
    pending: planPending,
    processing: planProcessing,
    completed: planCompleted,
    failed: planFailed,
  } = parsePlanItems(planItems);

  const { processing: addProcessing, failed: addFailed } = parseAddItems(addToAsstItems);

  return {
    planPending,
    planProcessing,
    planCompleted,
    planFailed,
    addProcessing,
    addFailed,
  };
}

function formatPlanData(items: {
  planPending: PlanItem[];
  planProcessing: PlanItem[];
  planCompleted: PlanItem[];
  planFailed: PlanItem[];
}) {
  const { planPending, planProcessing, planCompleted, planFailed } = items;
  return {
    pending: {
      items: reverse(sortBy(planPending.map(mapPlanItem), 'updatedAt')),
      badgeColor: getIconBadgeColor({ planPending, cssColorFormat: true }),
    },
    processing: {
      items: reverse(sortBy(planProcessing.map(mapPlanItem), 'updatedAt')),
      badgeColor: getIconBadgeColor({ planProcessing, cssColorFormat: true }),
    },
    completed: {
      items: reverse(sortBy(planCompleted.map(mapPlanItem), 'updatedAt')),
      badgeColor: getIconBadgeColor({ planCompleted, cssColorFormat: true }),
    },
    failed: {
      items: reverse(sortBy(planFailed.map(mapPlanItem), 'updatedAt')),
      badgeColor: getIconBadgeColor({ planFailed, cssColorFormat: true }),
    },
  };
}

function formatCartData(items: { addProcessing: AddToAssortmentItem[]; addFailed: AddToAssortmentItem[] }) {
  const { addProcessing, addFailed } = items;
  return {
    processing: {
      items: addProcessing.map(
        (item: AddToAssortmentItem): MinimalItem => ({
          primaryText: `Adding ${item.masterCount} items - ${formatAsstTime(item.updatedAt)}`,
          updatedAt: item.updatedAt,
        })
      ),
      badgeColor: getIconBadgeColor({ addProcessing, cssColorFormat: true }),
    },
    failed: {
      items: addFailed.map(
        (item: AddToAssortmentItem): MinimalItem => ({
          primaryText: item.cartId,
          updatedAt: item.updatedAt,
        })
      ),
      badgeColor: getIconBadgeColor({ addFailed, cssColorFormat: true }),
    },
  };
}

export function processPlanQueueItems(planItems: PlanItem[], addToAsstItems: AddToAssortmentItem[]) {
  const { planPending, planProcessing, planCompleted, planFailed, addProcessing, addFailed } = parsePlanQueueItems(
    planItems,
    addToAsstItems
  );

  const formattedPlanData = formatPlanData({ planPending, planProcessing, planCompleted, planFailed });
  const formattedCartData = formatCartData({ addProcessing, addFailed });

  return {
    processedPlanData: formattedPlanData,
    processedCartData: formattedCartData,
  };
}
